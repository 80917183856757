import React from 'react';
import bemClassNames from '../../../../../helpers/bemClassNames';

import '../../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const ClearButton = ({ clearDrawing }) => (
    <div className={cn('toolbar-group')}>
        <button onClick={clearDrawing} aria-label="Clear drawing" title="Clear drawing" className={cn('button')}>
            <span className={cn('icon')}>restart_alt</span>
        </button>
    </div>
);

export default ClearButton;
