import React from 'react';
import { setGlobal, useGlobal } from 'reactn';

// import components
import Modal from '../../Modal';
import PostItForm from '../../Forms/PostItForm';
import suggestTags from '../../../helpers/suggestions/suggestTags';

const PostIt = ({ linkData = null }) => {
    const [modalStatus] = useGlobal('postItModalStatus');
    const [modalData] = useGlobal('postItModal');
    const [data = null, view = null] = modalData || [];

    const closeModal = () => {
        setGlobal({
            postItModalStatus: null,
            postItModal: [null, false],
        });
    };

    const showModal = () => {
        setGlobal({
            postItModalStatus: true,
        });
        suggestTags(8);
    };

    return (
        <>
            <button className="o-button" onClick={() => showModal()}>
                Create post-it
            </button>
            {modalStatus && (
                <Modal closeModal={closeModal} title="Post-it">
                    <PostItForm closeModal={closeModal} data={data} view={view} linkData={linkData} />
                </Modal>
            )}
        </>
    );
};

export default PostIt;
