import React from 'react';
import './Wrapper.sass';
import { useGlobal } from 'reactn';
import { BrowserRouter as Router } from 'react-router-dom';

// import components
import Toast from '../../Notifications/Toast';

const Wrapper = ({ children }) => {
    const [notifications] = useGlobal('notifications');

    return (
        <Router>
            <div className="c-wrapper">
                {children}
                <Toast messages={notifications} />
            </div>
        </Router>
    );
};

export default Wrapper;
