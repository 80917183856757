import React, { useEffect, useGlobal } from 'reactn';
import './Archive.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

// import comonents
import Header from '../../components/Title';
import PageBody from '../../components/PageBody';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import getMeeting from '../../services/archive/get';

const cn = bemClassNames('c-archive');

const Archive = () => {
    const [meetings] = useGlobal('archived');
    const [user] = useGlobal('user');

    useEffect(() => {
        getMeeting();
    }, []);

    const createdByCheck = id => {
        if (user && user.id === id) {
            return true;
        }
        return false;
    };

    return (
        <div className={cn()}>
            <Header>
                <h1>Archive</h1>
            </Header>
            <PageBody>
                {meetings ? (
                    <div className={cn('grid')}>
                        {meetings.map(meeting => (
                            <div className={cn('card')} key={meeting.id}>
                                <Link to={'meetings/' + meeting.code} className={cn('link')}>
                                    <p className={cn('card-title')}>{meeting.title}</p>
                                    <div className={cn('card-info')}>
                                        <div className={cn('card-item')}>
                                            <div className={cn('card-icon')}>
                                                <span className={cn('icon')} aria-label="Amount of fields">
                                                    view_week
                                                </span>
                                            </div>
                                            <div className={cn('card-text')}>{meeting.fields.length} Fields</div>
                                        </div>
                                        <div className={cn('card-item')}>
                                            <div className={cn('card-icon')}>
                                                <span className={cn('icon')} aria-label="Creator of meeting">
                                                    library_add
                                                </span>
                                            </div>
                                            <div className={cn('card-text')}>
                                                Created by{' '}
                                                {createdByCheck(meeting.user_id) ? 'You' : meeting.display_name}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={cn('loading')}>
                        <Loading />
                    </div>
                )}
            </PageBody>
        </div>
    );
};

export default Archive;
