const useMarkdownOptions = ({ editorRef, setContent }) => {
    const insertTextStyle = char => {
        if (!editorRef.current) {
            return;
        }

        const originalValue = editorRef.current.value;
        const selectionStart = editorRef.current.selectionStart;
        const selectionEnd = editorRef.current.selectionEnd;
        const textBeforeSelection = originalValue.substring(0, selectionStart);
        const textAfterSelection = originalValue.substring(selectionEnd, originalValue.length);

        const selection = originalValue.substring(selectionStart, selectionEnd).trim();

        editorRef.current.value = textBeforeSelection + char + selection + char + textAfterSelection;

        setContent(editorRef.current.value);
    };

    const insertTextBlock = char => {
        if (!editorRef.current) {
            return;
        }

        let quote;

        const originalValue = editorRef.current.value;
        const selectionStart = editorRef.current.selectionStart;
        const selectionEnd = editorRef.current.selectionEnd;
        const textBeforeSelection = originalValue.substring(0, selectionStart);
        const textAfterSelection = originalValue.substring(selectionEnd, originalValue.length);

        const isRange = selectionStart < selectionEnd;

        if (isRange) {
            const selection = originalValue.substring(selectionStart, selectionEnd).trim();
            quote = '\n' + char + ' ' + selection + '\n\n';
        } else {
            quote = '\n' + char + ' ';
        }

        editorRef.current.value = textBeforeSelection + quote + textAfterSelection.trim();

        setContent(editorRef.current.value);
    };

    const insertCodeBlock = () => {
        if (!editorRef.current) {
            return;
        }

        let insert;

        const originalValue = editorRef.current.value;
        const selectionStart = editorRef.current.selectionStart;
        const selectionEnd = editorRef.current.selectionEnd;
        const textBeforeSelection = originalValue.substring(0, selectionStart);
        const textAfterSelection = originalValue.substring(selectionEnd, originalValue.length);
        const selection = originalValue.substring(selectionStart, selectionEnd);

        const isMultiLine = originalValue.substring(selectionStart, selectionEnd).includes('\n');

        if (isMultiLine) {
            insert = '\n```\n';
        } else {
            insert = '`';
        }

        editorRef.current.value = textBeforeSelection + insert + selection + insert + textAfterSelection;

        setContent(editorRef.current.value);
    };

    return {
        insertTextStyle,
        insertTextBlock,
        insertCodeBlock,
    };
};

export default useMarkdownOptions;
