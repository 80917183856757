import React, { useEffect, useState, useRef } from 'react';
import bemClassNames from '../../helpers/bemClassNames';
import './Filter.sass';
import Dropdown from 'react-bootstrap/Dropdown';

const cn = bemClassNames('c-filter');

const Filter = ({ data, setFilter }) => {
    const node = useRef();
    const [filterOptions, setFilterOptions] = useState([]);
    const [filterArray, setFilterArray] = useState([]);
    const [dropdownStatus, setDropdownStatus] = useState(false);

    useEffect(() => {
        if (data) {
            const tags = data.flatMap(value => value.tags.map(option => option.tag));
            const unique = new Set(tags);
            setFilterOptions([...unique]);
        }
    }, [data]);

    const addFilter = option => {
        setFilterArray([...filterArray, option]);
    };

    const removeFilter = option => {
        setFilterArray(previousFilter => previousFilter.filter(sortArray => sortArray !== option));
    };

    const filterEnabled = option => {
        if (filterArray.some(filter => option === filter)) {
            return true;
        }
        return false;
    };

    const removeAll = () => {
        setFilterArray([]);
        setDropdownStatus(!dropdownStatus);
    };

    useEffect(() => {
        setFilter(filterArray);
    }, [filterArray]);

    useEffect(() => {
        if (dropdownStatus) {
            document.addEventListener('mousedown', handleClick);
        } else {
            document.removeEventListener('mousedown', handleClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [dropdownStatus]);

    const handleClick = e => {
        if (node.current.contains(e.target)) {
            return;
        }

        if (dropdownStatus) {
            setDropdownStatus(false);
        }
    };

    return (
        <div className={cn()}>
            <Dropdown alignRight show={dropdownStatus} ref={node}>
                <Dropdown.Toggle
                    className={cn('button') + ' o-button'}
                    onClick={() => setDropdownStatus(!dropdownStatus)}
                >
                    Filter <span className={cn('icon', 'button-icon')}>filter_list</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className={cn('list')}>
                    {filterOptions.length > 0 ? (
                        <>
                            {filterArray.length > 0 && (
                                <Dropdown.Item href="#" onClick={() => removeAll()} className={cn('clear')}>
                                    Remove all <span className={cn('icon', 'filter-icon')}>clear_all</span>
                                </Dropdown.Item>
                            )}
                            {filterOptions.map(option =>
                                filterEnabled(option) ? (
                                    <Dropdown.Item
                                        key={option}
                                        href="#"
                                        onClick={() => removeFilter(option)}
                                        className={cn('filter--active')}
                                    >
                                        {option}
                                        <span className={cn('icon', 'filter-icon')}>close</span>
                                    </Dropdown.Item>
                                ) : (
                                    <Dropdown.Item
                                        className={cn('filter')}
                                        key={option}
                                        href="#"
                                        onClick={() => addFilter(option)}
                                    >
                                        {option}
                                    </Dropdown.Item>
                                ),
                            )}
                        </>
                    ) : (
                        <Dropdown.Item href="#">There are no tags to filter on</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default Filter;
