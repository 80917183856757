import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const deleteField = id => {
    axios
        .delete(`${process.env.REACT_APP_BACKEND_API}/field/${id}`)
        .then(() => {
            notification([
                {
                    message: 'Successfully removed field',
                    status: 'success',
                },
            ]);
        })
        .catch(error => {
            console.log(error);
        });
};

export default deleteField;
