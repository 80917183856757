import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const movePostIt = (code, postitId, fieldId) => {
    axios
        .put(`${process.env.REACT_APP_BACKEND_API}/post-it/${code}/${postitId}/${fieldId}`)
        .then(() => {
            notification([
                {
                    message: 'Successfully moved post-it',
                    status: 'success',
                },
            ]);
        })
        .catch(error => {
            console.log(error);
        });
};

export default movePostIt;
