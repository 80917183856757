import React from 'react';
import './Loading.sass';
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-loading');

const Loading = () => (
    <div className={cn()}>
        <div className={cn('container')}>
            <div className={cn('cube')}></div>
            <div className={cn('cube')}></div>
            <div className={cn('cube')}></div>
            <div className={cn('cube')}></div>
        </div>
        <p>Loading...</p>
    </div>
);

export default Loading;
