import React from 'react';
import ReactMarkdown from 'react-markdown';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-markdown-editor');

const MarkdownTextarea = ({ preview, name, content, rows, errors, editorRef, inputRef, setContent }) => (
    <>
        <div className={`${cn('preview')} ${!preview && 'hidden'}`}>
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
        <textarea
            placeholder="Body"
            className={`${cn('textarea')} ${preview && 'hidden'}`}
            name={name}
            id={name}
            aria-required="true"
            rows={rows}
            aria-invalid={errors.content ? 'true' : 'false'}
            defaultValue={content}
            ref={element => {
                inputRef(element);
                editorRef.current = element;
            }}
            onInput={e => setContent(e.target.value)}
        />
    </>
);

export default MarkdownTextarea;
