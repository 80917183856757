import { useState } from 'react';

// import services
import deletePostItService from '../../../../services/postit/delete';

const useDelete = ({ data, closeModal }) => {
    const [awaitDeleteConfirmation, setAwaitDeleteConfirmation] = useState(false);
    const [deletePostIt, setDeletePostIt] = useState(null);

    const deleteConfirm = () => {
        setAwaitDeleteConfirmation(true);
        setDeletePostIt(data.id);
    };

    const deletePostItAction = () => {
        deletePostItService(deletePostIt);
        setAwaitDeleteConfirmation(false);
        closeModal();
    };

    const cancelDeleteAction = () => {
        setAwaitDeleteConfirmation(false);
    };

    return {
        awaitDeleteConfirmation,
        deleteConfirm,
        deletePostItAction,
        cancelDeleteAction,
    };
};

export default useDelete;
