import React from 'react';
import bemClassNames from '../../../../helpers/bemClassNames';

const cn = bemClassNames('c-post-it');

const PostItOptions = ({ timeInfo, onDelete, onCreate }) => (
    <div className={cn('options') + ' o-input-group'}>
        <p className={cn('timeinfo')}>{timeInfo}</p>
        <button className={cn('action')} type="button" onClick={onDelete}>
            <span className={cn('edit')}>delete_outline</span>
        </button>
        <button className={cn('action')} type="button" onClick={onCreate}>
            <span className={cn('edit')}>create</span>
        </button>
    </div>
);

export default PostItOptions;
