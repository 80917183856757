import React from 'react';
import './PageBody.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-content-body');

const PageBody = ({ children }) => {
    return <div className={cn()}>{children}</div>;
};

export default PageBody;
