import React from 'react';
import './EditPasswordForm.sass';

// import services
import editPasswordService from '../../../services/account/password';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import components
import { useForm } from 'react-hook-form';
import Label from '../../Label';

const cn = bemClassNames('c-password-form');

const EditPasswordForm = ({ closeModal }) => {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => editPasswordService(data, closeModal);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="passwordInput" errors={errors.old_password}>
                    Old password
                </Label>
                <input
                    id="passwordInput"
                    type="password"
                    placeholder="Old password"
                    className="o-input"
                    name="old_password"
                    autoComplete="current-password"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.old_password ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <Label input="passwordInput" errors={errors.password}>
                    New password
                </Label>
                <input
                    id="passwordInput"
                    type="password"
                    placeholder="New password"
                    className="o-input"
                    name="password"
                    autoComplete="new-password"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.password ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <Label input="passwordConfirmationInput" errors={errors.password_confirmation}>
                    Password confirmation
                </Label>
                <input
                    id="passwordConfirmationInput"
                    type="password"
                    placeholder="Confirm password"
                    className="o-input"
                    name="password_confirmation"
                    autoComplete="new-password"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.password_confirmation ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <button type="submit" className="o-button">
                    Save new password
                </button>
            </div>
        </form>
    );
};

export default EditPasswordForm;
