import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const reset = (data, callback) => {
    axios.post(`${process.env.REACT_APP_BACKEND_API}/auth/reset`, data).then(() => {
        callback();
        notification([
            {
                message: 'An email with instruction has been send',
                status: 'success',
            },
        ]);
    });
};

export default reset;
