import React from 'react';
import bemClassNames from '../../../../../helpers/bemClassNames';

import '../../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const BrushColor = ({ brushColor, onChangeBrushColor }) => (
    <div className={cn('button')}>
        <div className={cn('input-color')}>
            <input
                id="brush-color-input"
                name="brush-color-input"
                type="color"
                value={brushColor}
                onChange={onChangeBrushColor}
            />
        </div>
    </div>
);

export default BrushColor;
