import React from 'react';
import bemClassNames from '../../helpers/bemClassNames';
import './Votes.sass';

const cn = bemClassNames('c-votes');

const Votes = data => {
    const nrOfVotesString = nrOfVotes => (nrOfVotes > 1 ? `${nrOfVotes} Votes` : `1 Vote`);

    if (!data.votes.length) {
        return (
            <div className={cn()}>
                <p className={cn('empty')}>no votes</p>
            </div>
        );
    }

    return (
        <div className={cn()}>
            <p className={cn('ammount')}>{nrOfVotesString(data.votes.length)}</p>
            <ul className={cn('list')}>
                {data.votes.map(vote => (
                    <li key={vote.id} className={cn('item')}>
                        <span className={cn('icon')}>arrow_upward</span>
                        <span className={cn('name')}>{vote.user.display_name}</span>
                        &nbsp;voted
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Votes;
