import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import { setGlobal } from 'reactn';

const details = (data, callback) => {
    axios.put(`${process.env.REACT_APP_BACKEND_API}/auth/user/details`, data).then(response => {
        notification([
            {
                message: 'Changed details',
                status: 'success',
            },
        ]);
        callback();
        setGlobal({
            user: response.data.data,
        });
    });
};

export default details;
