import React, { useRef, useEffect, useState } from 'react';
import colorGenerator from '../../helpers/colorGenerator';
import Tooltip from '../../components/Tooltip';
import bemClassNames from '../../helpers/bemClassNames';
import Dropdown from 'react-bootstrap/Dropdown';
import movePostItService from '../../services/postit/move';
import unlinkPostItService from '../../services/postit/unlink';
import { useGlobal } from 'reactn';
import Voting from './MeetingPostItVote';
import undoVote from '../../services/postit/undoVote';
import PostItView from '../../components/Modals/PostItView';
import setImportance from '../../services/postit/setImportance';
import ReactMarkdown from 'react-markdown';
import Drawing from '../../components/Drawing';

const cn = bemClassNames('c-meeting');

const MeetingPostIt = ({ postit, active, meeting, code, isScreenshot }) => {
    const postItRef = useRef();
    const [user] = useGlobal('user');
    const [detailView, setDetailView] = useState(false);

    useEffect(() => {
        if (postItRef.current && active) {
            postItRef.current.scrollIntoView();
        }
    }, [active, postItRef]);

    const setLessImportant = lessImportant => {
        const updatedPostit = { ...postit };
        updatedPostit.less_important = lessImportant ? 1 : 0;
        setImportance(code, postit.id, lessImportant ? 'less' : 'normal');
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
            ref={ref}
            className={cn('post-it-menu-trigger')}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </button>
    ));

    const dropItem = React.forwardRef(({ children, onClick }, ref) => (
        <a
            className="dropdown-item"
            ref={ref}
            href="#"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    ));

    const movePostIt = (postitId, fieldId) => {
        movePostItService(code, postitId, fieldId);
    };

    const createdByCheck = id => user && user.id === id;

    const unlinkPostIt = id => unlinkPostItService(code, id);

    const undoVotes = id => undoVote(id, code);

    const openDetails = () => setDetailView(true);

    const closeDetailView = () => setDetailView(false);

    const isLessImportant = postit.less_important;

    return (
        <li
            tabIndex="0"
            className={cn('field-post-it', {
                'field-post-it--highlight': active,
                'field-post-it--less-important': isLessImportant,
                'field-post-it--screenshot': isScreenshot,
            })}
            ref={postItRef}
        >
            {detailView && <PostItView closeModal={closeDetailView} data={postit} />}
            <p className={cn('post-it-title')}>{postit.title}</p>
            {postit.type === 'text' && (
                <div className={cn('content')}>
                    <ReactMarkdown>{postit.content}</ReactMarkdown>
                </div>
            )}
            {postit.type === 'drawing' && (
                <div className={cn('post-it-drawing')}>
                    <Drawing drawing={postit.drawing} disabled />{' '}
                </div>
            )}
            <div className={cn('post-it-actions')}>
                <Voting
                    votes={postit.votes}
                    postitId={postit.id}
                    userId={user.id}
                    code={code}
                    archived={meeting.archived}
                />
                <span
                    className={cn('post-it-creator')}
                    style={{
                        background: postit.creator.color || '#' + colorGenerator(postit.creator.display_name),
                    }}
                >
                    <Tooltip fullText={postit.creator.display_name}>
                        {postit.creator.initials || postit.creator.display_name.substr(0, 2).toUpperCase()}
                    </Tooltip>
                </span>
                {!isScreenshot && (
                    <Dropdown>
                        <Dropdown.Toggle id="custom" as={CustomToggle}>
                            <span className={cn('icon', 'post-it-menu')}>more_vert</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {!meeting.archived && (
                                <>
                                    {createdByCheck(postit.creator.id) && (
                                        <>
                                            <Dropdown.Item href="#" onClick={() => unlinkPostIt(postit.id)}>
                                                <span className={cn('icon', 'post-it-menu-icon')}>delete</span>
                                                Delete
                                            </Dropdown.Item>
                                        </>
                                    )}
                                    <Dropdown drop={'right'}>
                                        <Dropdown.Toggle id="custom" as={dropItem}>
                                            <span className={cn('icon', 'post-it-menu-icon')}>low_priority</span>
                                            Move to
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {meeting.fields.map(
                                                field =>
                                                    field.id !== postit.meeting_fields_id && (
                                                        <Dropdown.Item
                                                            href=""
                                                            key={field.id}
                                                            onClick={() => {
                                                                movePostIt(postit.id, field.id);
                                                            }}
                                                        >
                                                            {field.field}
                                                        </Dropdown.Item>
                                                    ),
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Dropdown.Item
                                        href="#"
                                        onClick={() => {
                                            undoVotes(postit.id);
                                        }}
                                    >
                                        <span className={cn('icon', 'post-it-menu-icon')}>undo</span>
                                        Undo votes
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setLessImportant(!isLessImportant)}>
                                        <span className={cn('icon', 'post-it-menu-icon')}>
                                            {isLessImportant ? 'trending_up' : 'trending_down'}
                                        </span>
                                        {isLessImportant
                                            ? 'Mark as important / not duplicate'
                                            : 'Mark as less important / duplicate'}
                                    </Dropdown.Item>
                                </>
                            )}
                            <Dropdown.Item href="#" onClick={() => openDetails()}>
                                <span className={cn('icon', 'post-it-menu-icon')}>aspect_ratio</span>
                                View details
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                )}
            </div>
        </li>
    );
};

export default MeetingPostIt;
