import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const updateField = (id, data) => {
    axios
        .put(`${process.env.REACT_APP_BACKEND_API}/field/${id}`, data)
        .then(() => {
            notification([
                {
                    message: 'Successfully updated field',
                    status: 'success',
                },
            ]);
        })
        .catch(error => {
            console.log(error);
        });
};

export default updateField;
