import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import getMeeting from '../get';

const deleteMeeting = id => {
    axios
        .delete(process.env.REACT_APP_BACKEND_API + '/meeting/' + id)
        .then(() => {
            notification([
                {
                    message: 'Successfully deleted meeting',
                    status: 'success',
                },
            ]);
            getMeeting();
        })
        .catch(error => {
            console.log(error);
        });
};

export default deleteMeeting;
