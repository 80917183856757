import axios from '../../axios';
import { setGlobal } from 'reactn';

const getPostit = (id = '', meetingCode = null, setDetails) => {
    const apiUrl = meetingCode ? `/post-it/${meetingCode}/${id}` : `/post-it/${id}`;
    axios
        .get(process.env.REACT_APP_BACKEND_API + apiUrl)
        .then(response => {
            if (id) {
                setDetails(response.data);
            } else {
                setGlobal({
                    postits: response.data,
                });
            }
        })
        .catch(error => {
            console.error(error);
            if (id && error.response.status === 403) {
                getPostit();
            }
        });
};

export default getPostit;
