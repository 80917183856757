import momentjs from 'moment';
import { useGlobal, setGlobal, useEffect } from 'reactn';

// import services
import updatePostit from '../../../../services/postit/update';
import storePostit from '../../../../services/postit/post';

export const usePostItForm = ({
    tags,
    canvasRef,
    type,
    isEditModus,
    data,
    closeModal,
    linkData,
    modalView,
    setTimeInfo,
    setError,
}) => {
    const [globalSuggestions] = useGlobal('tagSuggestions');
    const createTimeString = time => momentjs(time, 'YYYY-MM-DD HH:mm:ss').fromNow();

    const onSubmit = async formData => {
        const input = document.querySelector('#tagInput').value.trim();
        if (input && !tags.some(item => input === item.tag) && input !== '' && input.length <= 25) {
            formData.tags = [...tags, { tag: input }];
        } else {
            formData.tags = tags;
        }
        if (type === 'drawing' && canvasRef.current) {
            formData.drawing = canvasRef.current.getDrawing();
        }
        if (isEditModus) {
            formData.id = data.id;
            updatePostit(formData, closeModal);
            return;
        }
        const postitStored = await storePostit(formData, linkData);
        if (!postitStored) {
            setError('title', 'manual', 'Er is iets fout gegaan met de titel, probeer opnieuw');
            setError('content', 'manual', 'Er is iets fout gegaan met de contents, probeer opnieuw');
            return;
        }
        document.querySelector('#title').focus();

        const newTags = [];
        for (const { tag } of tags) {
            if (globalSuggestions.includes(tag)) continue;
            newTags.push(tag);
        }
        setGlobal({
            tagSuggestions: [...newTags, ...globalSuggestions],
        });

        if (canvasRef.current) {
            canvasRef.current.clear();
        }

        closeModal();
    };

    const suggestions = globalSuggestions?.filter(suggestedTag => tags.every(({ tag }) => suggestedTag !== tag));

    useEffect(() => {
        if (modalView) {
            if (data.created_at !== data.updated_at) {
                setTimeInfo(`Updated ${createTimeString(data.updated_at)}`);
            } else {
                setTimeInfo(`Created ${createTimeString(data.created_at)}`);
            }
        }
    }, [modalView, data]);

    return {
        onSubmit,
        suggestions,
    };
};
