import React from 'react';

// import components
import { useForm } from 'react-hook-form';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import services
import Label from '../../Label';
import updateMeeting from '../../../services/meeting/update';

const cn = bemClassNames('c-meeting');

const MeetingTitleForm = ({ id, title, closeModal }) => {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {
        updateMeeting(id, data);
        closeModal();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="meetingTitleInput" errors={errors.meeting_id}>
                    Meeting title
                </Label>
                <input
                    type="text"
                    placeholder="Meeting Title"
                    className="o-input"
                    name="title"
                    ref={register({
                        required: true,
                    })}
                    defaultValue={title}
                />
            </div>
            <div className={cn('action') + ' o-input-group'}>
                <button type="submit" className="o-button">
                    Change title
                </button>
            </div>
        </form>
    );
};

export default MeetingTitleForm;
