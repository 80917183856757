import React, { useState } from 'react';

// import components
import Modal from '../../Modal';
import ResetPasswordForm from '../../Forms/ResetPasswordForm/ResetPasswordForm';

const ResetPassword = () => {
    const [showModal, setShowModal] = useState(false);

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <span>
                Forgot your password?&nbsp;
                <button className="o-button-link" type="button" onClick={() => setShowModal(true)}>
                    Reset password
                </button>
            </span>
            {showModal && (
                <Modal closeModal={closeModal} title="Reset password">
                    <ResetPasswordForm closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default ResetPassword;
