import React from 'react';
import './Reset.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

// import services

// import components
import ResetPasswordForm from '../../components/Forms/ResetPasswordFinalForm';
import Label from '../../components/Label';

const cn = bemClassNames('c-reset');

const Reset = ({ match }) => {
    const token = match.params.token;
    const email = match.params.email;

    return (
        <div className={cn()} role="main">
            <div className={cn('logo')}>
                <img src="/images/logo/logo.png" className={cn('logo-image')} alt="Logo" />
            </div>
            <div className={cn('text')}>
                <h1 className={cn('title')}>Retrotool</h1>
                <p className={cn('sub-title')}>Look back and deal with past events or situations by using Retrotool.</p>
            </div>
            <div className={cn('form')}>
                <Label>Email</Label>
                <p>{email}</p>
                <ResetPasswordForm token={token} />
            </div>
        </div>
    );
};

export default Reset;
