import React from 'react';
import './AddFieldForm.sass';

// import components
import { useForm } from 'react-hook-form';

// import services
import Label from '../../Label';
import postField from '../../../services/field/post';
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-add-field-form');

const AddFieldForm = ({ id, closeModal }) => {
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = data => {
        data.meeting_id = id;
        postField(data, closeModal);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="meetingTitleInput" errors={errors.meeting_id}>
                    Field name
                </Label>
                <input
                    type="text"
                    placeholder="Field Title"
                    className="o-input"
                    name="field"
                    ref={register({
                        required: true,
                    })}
                />
            </div>
            <div className="o-input-group">
                <button type="submit" className="o-button">
                    Create field
                </button>
            </div>
        </form>
    );
};

export default AddFieldForm;
