import React from 'react';
import './Auth.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

// import components
import RegisterModal from '../../components/Modals/Register';
import Login from '../../components/Forms/LoginForm';
import ResetPassword from '../../components/Modals/ResetPassword';

const cn = bemClassNames('c-auth');

const Auth = () => {
    return (
        <div className={cn()} role="main">
            <div className={cn('content')}>
                <div className={cn('form-wrapper')}>
                    <div className={cn('logo')}>
                        <img src="/images/logo/logo.png" className={cn('logo-image')} alt="Logo" />
                    </div>
                    <div className={cn('text')}>
                        <h1 className={cn('title')}>Retrotool</h1>
                        <p className={cn('sub-title')}>
                            Look back and deal with past events or situations by using Retrotool.
                        </p>
                    </div>
                    <div className={cn('action')}>
                        <Login />
                    </div>
                    <div className={cn('options')}>
                        <ResetPassword />
                        <span className={cn('register-text')}>
                            Don&lsquo;t have an account yet? <RegisterModal />
                        </span>
                    </div>
                </div>
            </div>
            <div className={cn('visual')}>
                <div className={cn('background')}>
                    <div className={cn('mockup')}>
                        <div className={cn('taskbar')}>
                            <div className={cn('taskbar-circle')}></div>
                            <div className={cn('taskbar-circle')}></div>
                            <div className={cn('taskbar-circle')}></div>
                        </div>
                        <div className={cn('screenshot')}>
                            <img
                                className={cn('screenshot-img')}
                                src="/images/background/dashboard_mockup.png"
                                alt="Mockup screenshot of program"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
