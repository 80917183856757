import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import './Modal.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-modal');

const Modal = ({ children, closeModal, title, fixedDimensions = false }) => {
    const modal = useRef();
    const content = useRef();
    const [dimensions, setDimensions] = useState(null);

    const escCheck = e => {
        if (e.keyCode === 27) {
            closeModal();
        }
    };

    useEffect(() => {
        modal.current.focus();
    }, []);

    useLayoutEffect(() => {
        if (fixedDimensions) {
            setDimensions({ height: content.current.clientHeight, width: content.current.clientWidth });
        }
    }, [fixedDimensions]);

    return (
        <div className={cn()} ref={modal} tabIndex={0} onKeyDown={escCheck} role="dialog">
            <div className={cn('background')}></div>

            <div className={cn('modal-inner')} aria-live="polite">
                <div className={cn('container')}>
                    <div className={cn('container-inner')}>
                        <div className={cn('header')}>
                            <h3 className={cn('title')}>{title}</h3>
                            <button className={cn('close-button')} onClick={closeModal}>
                                <span className={cn('close-icon')}>close</span>
                            </button>
                        </div>
                        <div className={cn('content')}>
                            <div className={cn('content-wrapper')}>
                                <div
                                    className={cn('content-body')}
                                    ref={content}
                                    style={fixedDimensions ? dimensions : null}
                                >
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
