import React, { useState } from 'react';
import './MeetingJoinForm.sass';
import { Redirect } from 'react-router-dom';

// import components
import { useForm } from 'react-hook-form';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import services
import Label from '../../Label';
import joinMeeting from '../../../services/meeting/join';

const cn = bemClassNames('c-meeting');

const MeetingJoinForm = () => {
    const { register, handleSubmit, errors } = useForm();
    const [joined, setJoined] = useState(false);

    const onSubmit = data => {
        joinMeeting(data, redirect);
    };

    const redirect = code => {
        setJoined(code);
    };

    return (
        <>
            {joined ? (
                <Redirect to={'/meetings/' + joined} />
            ) : (
                <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
                    <div className="o-input-group">
                        <Label input="meetingCodeInput" errors={errors.meeting_id}>
                            Meeting Code
                        </Label>
                        <input
                            type="text"
                            placeholder="Meeting Code"
                            className="o-input"
                            name="meeting_id"
                            id="meetingTitleInput"
                            ref={register({
                                required: true,
                            })}
                        />
                    </div>
                    <div className={cn('action') + ' o-input-group'}>
                        <button type="submit" className="o-button">
                            Submit code
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default MeetingJoinForm;
