import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';
import Toolbar from './components/Toolbar/Toolbar';
import bemClassNames from '../../helpers/bemClassNames';
import LZString from 'lz-string';

import './Drawing.sass';
import { useDrawing } from './hooks/useDrawing';

const cn = bemClassNames('c-drawing');

const Drawing = forwardRef(({ drawing, disabled = false, className, noBorder = false }, drawingRef) => {
    const canvasRef = useRef(null);
    const {
        canvasWidth,
        brushSize,
        brushColor,
        hideGrid,
        brushSizeSliderVisible,
        gridColor,
        canvasHeight,
        uncompressedDrawing,
        brushRadius,
        setBrushSize,
        setHideGrid,
        toggleBrushSizeSliderVisibility,
        undoDraw,
        clearDrawing,
        setWrapperRef,
        onChangeBrushColor,
    } = useDrawing({ canvasRef, drawing });

    useImperativeHandle(drawingRef, () => ({
        getDrawing: () => {
            const drawing = canvasRef.current.getSaveData();
            return LZString.compressToUTF16(drawing);
        },
        undo: () => canvasRef.current.undo(),
        clear: () => canvasRef.current.clear(),
    }));

    return (
        <div ref={setWrapperRef} className={cn('wrapper')}>
            {!disabled && (
                <Toolbar
                    setHideGrid={setHideGrid}
                    setBrushSize={setBrushSize}
                    toggleBrushSizeSliderVisibility={toggleBrushSizeSliderVisibility}
                    undoDraw={undoDraw}
                    clearDrawing={clearDrawing}
                    onChangeBrushColor={onChangeBrushColor}
                    brushSize={brushSize}
                    brushColor={brushColor}
                    hideGrid={hideGrid}
                    brushSizeSliderVisible={brushSizeSliderVisible}
                />
            )}
            <CanvasDraw
                ref={canvasRef}
                disabled={disabled}
                saveData={uncompressedDrawing}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                lazyRadius={0}
                brushRadius={brushRadius}
                brushColor={brushColor}
                catenaryColor={brushColor}
                gridColor={gridColor}
                className={`${cn('canvas', { 'canvas--noBorder': noBorder })} ${className}`.trim()}
                hideInterface={disabled}
            />
        </div>
    );
});

export default Drawing;
