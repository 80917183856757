import React, { useEffect, useGlobal, setGlobal } from 'reactn';
import './PostIts.sass';
import { Link } from 'react-router-dom';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';
import filterPostitsHelper from '../../helpers/filters/filterPostits';

// import service
import getPostits from '../../services/postit/get';

// import comonents
import Header from '../../components/Title';
import PageBody from '../../components/PageBody';
import Tags from '../../components/Tag';
import Loading from '../../components/Loading';
import AddPostIt from '../../components/Modals/PostIt';
import Filter from '../../components/Filter';
import { useState } from 'react';
import Drawing from '../../components/Drawing';
import ReactMarkdown from 'react-markdown';

const cn = bemClassNames('c-postits');

const PostIts = () => {
    const [postits] = useGlobal('postits');
    const [localPostits, setLocalPostits] = useState([]);
    const [filterArray, setFilterArray] = useState([]);

    const setFilter = filter => {
        if (filter.length > 0) {
            setFilterArray(filter);
        }
        setLocalPostits(postits);
    };

    useEffect(() => {
        if (!postits) {
            return;
        }
        if (filterArray.length === 0) {
            setLocalPostits(postits);
            return;
        }
        setLocalPostits(filterPostitsHelper(postits, filterArray));
    }, [postits, filterArray]);

    useEffect(() => {
        getPostits();
    }, []);

    const setDetails = data => {
        setGlobal({
            postItModalStatus: true,
            postItModal: [data, true],
        });
    };

    const handleKeyPress = (event, id) => {
        const KEY_ENTER = 13;
        if (event.which === KEY_ENTER) {
            openDetails(id);
        }
    };

    const openDetails = id => {
        getPostits(id, null, setDetails);
    };

    const shouldShowPostIt = postit => {
        if (postit.meeting_fields_id && !postit.meeting_fields.meeting.archived) {
            return postit;
        }
    };

    return (
        <div className={cn()}>
            <Header>
                <h1>Post-its</h1>
            </Header>
            <PageBody>
                <div className={cn('actions')}>
                    <AddPostIt />
                    <Filter data={postits} setFilter={setFilter} />
                </div>
                {localPostits ? (
                    <>
                        <div className={cn('grid')}>
                            {localPostits.map(
                                postit =>
                                    !postit.meeting_fields_id && (
                                        <div
                                            className={cn('card')}
                                            key={postit.id}
                                            tabIndex="0"
                                            onClick={() => openDetails(postit.id)}
                                            onKeyPress={event => handleKeyPress(event, postit.id)}
                                        >
                                            <h3 className={cn('card-title')}>{postit.title}</h3>
                                            {postit.type === 'text' && <ReactMarkdown>{postit.content}</ReactMarkdown>}
                                            {postit.type === 'drawing' && (
                                                <div className={cn('card-drawing')}>
                                                    <Drawing disabled drawing={postit.drawing} noBorder />
                                                </div>
                                            )}
                                            <div className={cn('card-tags')}>
                                                <Tags tags={postit.tags} />
                                            </div>
                                        </div>
                                    ),
                            )}
                        </div>
                        <div className={cn('grid', 'linked')}>
                            {localPostits.map(
                                postit =>
                                    shouldShowPostIt(postit) && (
                                        <div
                                            className={cn('card')}
                                            key={postit.id}
                                            tabIndex="0"
                                            onClick={() => openDetails(postit.id)}
                                            onKeyPress={event => handleKeyPress(event, postit.id)}
                                        >
                                            <h3 className={cn('card-title')}>{postit.title}</h3>
                                            {postit.type === 'text' && <ReactMarkdown>{postit.content}</ReactMarkdown>}
                                            {postit.type === 'drawing' && (
                                                <div className={cn('card-drawing')}>
                                                    <Drawing disabled drawing={postit.drawing} noBorder />
                                                </div>
                                            )}
                                            <div className={cn('card-tags')}>
                                                <Tags tags={postit.tags} />
                                            </div>
                                            <Link
                                                onClick={e => {
                                                    e.stopPropagation();
                                                }}
                                                to={
                                                    '/meetings/' +
                                                    postit.meeting_fields.meeting.code +
                                                    '#postit' +
                                                    postit.id
                                                }
                                            >
                                                <div className={cn('meeting')}>
                                                    <span className={cn('icon')}>supervisor_account</span>
                                                    {postit.meeting_fields.meeting.title}
                                                </div>
                                            </Link>
                                        </div>
                                    ),
                            )}
                        </div>
                    </>
                ) : (
                    <div className={cn('loading')}>
                        <Loading />
                    </div>
                )}
            </PageBody>
        </div>
    );
};

export default PostIts;
