import React, { useEffect, useState } from 'react';
import './Verify.sass';
import { Link } from 'react-router-dom';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

// import services
import verifyService from '../../services/verify';

// import components
import Block from '../../components/Notifications/Block';

const cn = bemClassNames('c-verify');

const Verify = ({ match }) => {
    const [verify, setVerify] = useState([]);

    const setStatus = status => {
        setVerify(status);
    };

    const token = match.params.token;

    useEffect(() => {
        verifyService({ setStatus, token });
    }, [token]);

    return (
        <div className={cn()} role="main">
            {verify.status ? (
                <Block data={verify}>
                    <Link to="/">
                        <button className="o-button">Start using the Retrotool</button>
                    </Link>
                </Block>
            ) : (
                <Block data={verify}>
                    <Link to="/">
                        <button className="o-button">Go to login screen</button>
                    </Link>
                </Block>
            )}
        </div>
    );
};

export default Verify;
