import { useEffect, useGlobal } from 'reactn';

export const AUTO_SCROLL_KEY = 'autoScroll';

export const useAutoScrollState = () => {
    const [autoScroll, setScroll] = useGlobal('autoScroll');

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const currentAutoScroll = localStorage.getItem(AUTO_SCROLL_KEY);

        setScroll(currentAutoScroll === 'true');
    }, []);

    const setAutoScroll = () => {
        if (typeof window === 'undefined') return;

        const newAutoScroll = !autoScroll;
        localStorage.setItem(AUTO_SCROLL_KEY, newAutoScroll);
        setScroll(newAutoScroll);
    };

    return {
        autoScroll,
        setAutoScroll,
    };
};
