const sortField = field => {
    if (!['votes', 'creator'].includes(field.sort)) {
        return field.post_its;
    }
    if (field.sort === 'votes') {
        return field.post_its.sort((a, b) => {
            if (b.less_important && !a.less_important) {
                return -1;
            }
            if (a.less_important && !b.less_important) {
                return 1;
            }
            return b.votes.length - a.votes.length;
        });
    }
    return field.post_its.sort((a, b) => {
        if (b.less_important && !a.less_important) {
            return -1;
        }
        if (a.less_important && !b.less_important) {
            return 1;
        }
        if (a.creator.display_name < b.creator.display_name) {
            return 1;
        }
        if (a.creator.display_name > b.creator.display_name) {
            return -1;
        }
        return 0;
    });
};

export default sortField;
