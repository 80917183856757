import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const password = (data, callback) => {
    axios
        .put(`${process.env.REACT_APP_BACKEND_API}/auth/user/password`, data)
        .then(() => {
            notification([
                {
                    message: 'Changed password',
                    status: 'success',
                },
            ]);
            callback();
        })
        .catch(error => {
            if (error.response.status === 403) {
                notification([
                    {
                        message: 'Wrong password',
                        status: 'error',
                    },
                ]);
            }
        });
};

export default password;
