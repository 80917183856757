import React, { useState } from 'react';
import './MeetingJoin.sass';

// import components
import Modal from '../../Modal';
import MeetingJoinForm from '../../Forms/MeetingJoinForm';
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-meeting-join');

const Meeting = () => {
    const [modalShow, setModalShow] = useState(false);

    const closeModal = () => {
        setModalShow(false);
    };

    const showModal = () => {
        setModalShow(true);
    };

    return (
        <>
            <button className={cn() + ' o-button'} onClick={() => showModal()}>
                Join meeting
            </button>
            {modalShow && (
                <Modal closeModal={closeModal} title="Join Meeting">
                    <MeetingJoinForm />
                </Modal>
            )}
        </>
    );
};

export default Meeting;
