import React, { useState } from 'react';
import './MeetingTitleEdit.sass';

// import components
import Modal from '../../Modal';
import bemClassNames from '../../../helpers/bemClassNames';
import MeetingTitleForm from '../../Forms/MeetingTitleForm';

const cn = bemClassNames('c-meeting-title-edit');

const MeetingTitleEdit = ({ id, title }) => {
    const [modalShow, setModalShow] = useState(false);

    const closeModal = () => {
        setModalShow(false);
    };

    return (
        <>
            <button className={cn('button')} onClick={() => setModalShow(true)}>
                <span className={cn('icon', 'action')}>edit</span>
            </button>
            {modalShow && (
                <Modal closeModal={closeModal} title="Change meeting title">
                    <MeetingTitleForm id={id} title={title} closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default MeetingTitleEdit;
