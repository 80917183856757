import axios from '../axios';
import notification from '../../helpers/errorHandling';

const register = (data, callback) => {
    axios.post(process.env.REACT_APP_BACKEND_API + '/auth/register', data).then(function () {
        callback();
        notification([
            {
                message: 'Your account has been registered. Please check your mail to activate your account',
                status: 'success',
            },
        ]);
    });
};

export default register;
