import axios from '../axios';
import { setGlobal } from 'reactn';
import colorGenerator from '../../helpers/colorGenerator';

const userinfo = () => {
    setGlobal({
        user: null,
    });

    axios.get(process.env.REACT_APP_BACKEND_API + '/auth/user').then(function (response) {
        if (!response.data.data.color) {
            response.data.data.color = '#' + colorGenerator(response.data.data.name);
        }
        if (!response.data.data.initials) {
            response.data.data.initials = response.data.data.name.substring(0, 2).toUpperCase();
        }
        setGlobal({
            user: response.data.data,
        });
    });
};

export default userinfo;
