import React from 'react';
import bemClassNames from '../../../../../helpers/bemClassNames';

import '../../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const ToolbarGroup = ({ children }) => <div className={cn('toolbar-group')}>{children}</div>;

export default ToolbarGroup;
