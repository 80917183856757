import React from 'react';
import './Label.sass';

// import components
import InputError from '../Notifications/InputError';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-label');

const Label = ({ errors, input, children }) => (
    <label className={cn()} htmlFor={input}>
        {children}
        {errors && <InputError message={errors.message} />}
    </label>
);

export default Label;
