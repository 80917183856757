import React, { useState } from 'react';
import './MeetingForm.sass';

// import components
import { useForm } from 'react-hook-form';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import services
import Label from '../../Label';
import Fields from '../../Field';
import submitMeeting from '../../../services/meeting/post';
import notification from '../../../helpers/errorHandling';

const cn = bemClassNames('c-meeting');

const MeetingForm = ({ closeModal }) => {
    const { register, handleSubmit, errors, setValue } = useForm();
    const onSubmit = data => {
        data.fields = fields;
        submitMeeting(data, closeModal);
    };

    const [fields, setFields] = useState([]);

    const handleFieldEvent = e => {
        if (e.key === 'Enter' || e.target.id === 'field-trigger') {
            const input = document.querySelector('#fieldInput').value.trim();
            e.preventDefault();
            if (fields.some(item => input === item.field)) {
                notification([
                    {
                        message: 'This field already exists',
                        status: 'warning',
                    },
                ]);
            } else if (input.length > 25) {
                notification([
                    {
                        message: 'You can only use 25 characters',
                        status: 'warning',
                    },
                ]);
            } else if (input !== '') {
                setFields([...fields, { field: input }]);
                setValue('fields', '');
            }
        }
    };

    const removeField = removeField => {
        setFields(previousFields => previousFields.filter(({ field }) => field !== removeField));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="meetingTitleInput" errors={errors.title}>
                    Title
                </Label>
                <input
                    type="text"
                    placeholder="Titel"
                    className="o-input"
                    name="title"
                    id="meetingTitleInput"
                    ref={register({
                        required: true,
                    })}
                />
            </div>
            <div className="o-input-group">
                <Label input="fieldInput">Fields</Label>
                <Fields field={fields} removeField={removeField} />
                <div className="o-input-group-addon">
                    <input
                        type="text"
                        placeholder="Fields (press enter to add)"
                        className="o-input"
                        name="fields"
                        id="fieldInput"
                        ref={register({
                            required: false,
                        })}
                        aria-invalid={errors.field ? 'true' : 'false'}
                        onKeyPress={handleFieldEvent}
                    />
                    <button id="field-trigger" className="o-button" type="button" onClick={handleFieldEvent}>
                        Add
                    </button>
                </div>
            </div>
            <div className={cn('action') + ' o-input-group'}>
                <button type="submit" className="o-button">
                    Save meeting
                </button>
            </div>
        </form>
    );
};

export default MeetingForm;
