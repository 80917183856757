import axios from '../../axios';
import { setGlobal } from 'reactn';
import notification from '../../../helpers/errorHandling';
import getMeeting from '../get';

const postMeeting = (data, closeModal) => {
    axios
        .post(process.env.REACT_APP_BACKEND_API + '/meeting/', data)
        .then(response => {
            notification([
                {
                    message: 'Successfully created meeting',
                    status: 'success',
                },
            ]);
            getMeeting();
            closeModal();
            setGlobal({
                meetingModalStatus: true,
                meetingModal: response.data.data,
            });
        })
        .catch(error => {
            console.log(error);
        });
};

export default postMeeting;
