import { useState } from 'react';

const useEdit = ({ data, view = true, setTags, setType }) => {
    const [modalView, setModalView] = useState(view);
    const [isEditModus, setIsEditModus] = useState(false);

    const editMode = () => {
        if (!isEditModus) {
            setModalView(false);
            setIsEditModus(true);
            setTags(data.tags);
        }
    };

    const cancelEdit = () => {
        setModalView(true);
        setIsEditModus(false);
        setType(data?.type);
    };
    return {
        modalView,
        isEditModus,
        editMode,
        cancelEdit,
    };
};
export default useEdit;
