const addOrReplacePostit = (postitList, postit) => {
    const existingPostitIndex = postitList.findIndex(currentPostit => currentPostit.id === postit.id);

    if (existingPostitIndex > -1) {
        postitList.splice(existingPostitIndex, 1, { ...postit });
        return;
    }
    postitList.push(postit);
};

export default addOrReplacePostit;
