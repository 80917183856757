import React, { useState } from 'react';

// import components
import Modal from '../../Modal';
import EditPasswordForm from '../../Forms/EditPasswordForm';

const EditPassword = () => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <button className="o-button" href="#" onClick={() => setShowModal(true)}>
                Edit
            </button>
            {showModal && (
                <Modal closeModal={closeModal} title="Edit password">
                    <EditPasswordForm closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default EditPassword;
