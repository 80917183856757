import React from 'react';
import bemClassNames from '../../../../helpers/bemClassNames';

const cn = bemClassNames('c-post-it');

const PostItActions = ({ editMode, onClick }) => {
    if (editMode)
        return (
            <div className={cn('button') + ' o-input-group'}>
                <button type="button" className={cn('cancel') + ' o-button o-button-secondary'} onClick={onClick}>
                    Cancel
                </button>
                <button type="submit" className="o-button">
                    Save changes
                </button>
            </div>
        );

    return (
        <div className={cn('button') + ' o-input-group'}>
            <button type="submit" className="o-button">
                Create post-it
            </button>
        </div>
    );
};

export default PostItActions;
