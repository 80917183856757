import { useRef, useState, useEffect } from 'react';

import html2canvas from 'html2canvas';

const useTakeScreenshot = downloadName => {
    const screenshotElementRef = useRef(null);
    const [isScreenshot, setIsScreenshot] = useState(false);
    const screenshotPromiseResolveRef = useRef(() => {});

    const triggerScreenshotDownload = () => {
        setIsScreenshot(true);
        return new Promise(resolve => {
            screenshotPromiseResolveRef.current = resolve;
        });
    };

    const createScreenshot = async () => {
        const canvas = await html2canvas(screenshotElementRef.current);
        const dataUrl = canvas.toDataURL('image/jpeg');
        return dataUrl;
    };

    const triggerDownload = dataUrl => {
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = `${downloadName}.jpg`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const createAndDownloadScreenshot = async () => {
        const dataUrl = await createScreenshot();

        triggerDownload(dataUrl);

        screenshotPromiseResolveRef.current();
    };

    useEffect(() => {
        if (!isScreenshot) {
            return;
        }
        if (!screenshotElementRef.current) {
            return;
        }
        createAndDownloadScreenshot();

        setIsScreenshot(false);
    }, [isScreenshot]);

    return { triggerScreenshotDownload, screenshotElementRef, isScreenshot };
};

export default useTakeScreenshot;
