import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import getPostit from '../get';
import linkPostIt from '../link';

const postPostit = async (data, linkData) => {
    try {
        const response = await axios.post(process.env.REACT_APP_BACKEND_API + '/post-it/', data);
        notification([
            {
                message: 'Successfully created post-it',
                status: 'success',
            },
        ]);
        if (linkData) {
            linkPostIt(linkData.code, response.data.data.id, linkData.field);
        }
        getPostit();
    } catch (error) {
        console.log(error);
        return false;
    }
    return true;
};

export default postPostit;
