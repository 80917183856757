import axios from '../axios';
import { setGlobal } from 'reactn';
import notification from '../../helpers/errorHandling';
import setUserinfo from '../userinfo';
import { socketAuth } from '../../helpers/laravelEcho/laravelEcho';

const login = ({ data }) => {
    axios
        .post(process.env.REACT_APP_BACKEND_API + '/auth/login', data)
        .then(function (response) {
            localStorage.setItem('token', 'Bearer ' + response.data.data.access_token);
            localStorage.setItem('expires_at', response.data.data.expires_at);
            socketAuth();
            setGlobal({
                loggedIn: true,
            });
            setUserinfo();
            notification([
                {
                    message: 'Successfully logged in',
                    status: 'success',
                },
            ]);
        })
        .catch(error => {
            switch (error.response.status) {
                case 403:
                    notification([
                        {
                            message: 'Please check your email and activate your account first',
                            status: 'warning',
                        },
                    ]);
                    break;
                case 401:
                    notification([
                        {
                            message: 'You entered the wrong credentials, please try again',
                            status: 'error',
                        },
                    ]);
                    break;
                default:
                    notification([
                        {
                            message: 'Something went wrong, please try again',
                            status: 'error',
                        },
                    ]);
                    break;
            }
        });
};

export default login;
