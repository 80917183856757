import React from 'react';
import './Logout.sass';
import { useForm } from 'react-hook-form';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import components
import logout from '../../../services/logout';

const cn = bemClassNames('c-account-logout');

const Logout = () => {
    const { handleSubmit } = useForm();
    const onSubmit = () => logout();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <button type="submit" className="o-button" id="logoutInput">
                Logout
            </button>
        </form>
    );
};

export default Logout;
