import React from 'react';
import Label from '../../../Label';
import Tags from '../../../Tag';
import Suggestions from '../../../Suggestions';

const InputPostItTags = ({
    name,
    tags = [],
    inputRef,
    errors,
    suggestions,
    removeTag,
    onKeyPress,
    onClick,
    callback,
}) => (
    <div className="o-input-group">
        <Label input={name}>Tags</Label>
        <Tags tags={tags} removeTag={removeTag} />
        <div className="o-input-group-addon">
            <input
                type="text"
                placeholder="Tags (press enter to add)"
                className="o-input"
                name={name}
                id={name}
                ref={inputRef}
                maxLength="25"
                aria-invalid={errors && errors.tag ? 'true' : 'false'}
                onKeyPress={onKeyPress}
                onKeyUp={e => {
                    if (e.keyCode === 13) e.target.value = '';
                }}
            />
            <button id="tag-trigger" className="o-button" type="button" onClick={onClick}>
                Add
            </button>
        </div>
        <Suggestions title="Recent tags:" suggestions={suggestions} callback={callback} />
    </div>
);

export default InputPostItTags;
