import axios from '../axios';
import notification from '../../helpers/errorHandling';
import { setGlobal } from 'reactn';

const logout = () => {
    axios.post(process.env.REACT_APP_BACKEND_API + '/auth/logout').then(function () {
        localStorage.removeItem('token');
        setGlobal({
            loggedIn: false,
        });
        notification([
            {
                message: 'Successfully logged out',
                status: 'success',
            },
        ]);
    });
};

export default logout;
