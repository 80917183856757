import React from 'react';
import './Block.sass';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-block');

const Block = ({ data, children }) => {
    return (
        <div className={cn()}>
            <div className={cn('header')}>
                <span className={cn('icon') + ' ' + cn({ 'icon--success': data.status })}></span>
            </div>
            <div className={cn('title')}>
                <h3>{data.title}</h3>
            </div>
            <div className={cn('text')}>
                <p>{data.text}</p>
            </div>
            <div className={cn('children')}>{children}</div>
        </div>
    );
};

export default Block;
