import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const postField = (data, closeModal) => {
    axios
        .post(`${process.env.REACT_APP_BACKEND_API}/field`, data)
        .then(() => {
            notification([
                {
                    message: 'Successfully created field',
                    status: 'success',
                },
            ]);
            closeModal();
        })
        .catch(error => {
            console.log(error);
        });
};

export default postField;
