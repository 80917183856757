import React from 'reactn';
import './PostItView.sass';

// import components
import Modal from '../../Modal';
import Votes from '../../Votes';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-postit-view');

const PostItView = ({ closeModal, data }) => (
    <Modal closeModal={closeModal} title="Post-it">
        <p className={cn('title')}>{data.title}</p>
        <p className={cn('body')}>{data.content}</p>
        <div className={cn('votes')}>
            <Votes votes={data.votes} />
        </div>
    </Modal>
);

export default PostItView;
