import React from 'react';
import bemClassNames from '../../../../../helpers/bemClassNames';

import '../../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const UndoButton = ({ undoDraw }) => (
    <div className={cn('toolbar-group')}>
        <button onClick={undoDraw} aria-label="Undo last line" title="Undo last line" className={cn('button')}>
            <span className={cn('icon')}>undo</span>
        </button>
    </div>
);

export default UndoButton;
