import React from 'react';
import './Suggestions.sass';
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-suggestions');

const Suggestions = ({ title, suggestions, callback }) => {
    if (!suggestions || !suggestions.length) {
        return null;
    }

    return (
        <div className={cn()}>
            <h5 className={cn('title')}>{title}</h5>
            <ul className={cn('list')}>
                {suggestions.map(suggestion => (
                    <li className={cn('item')} key={suggestion} onClick={() => callback(suggestion)}>
                        <button type="button" className={cn('button')}>
                            {suggestion}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Suggestions;
