import React, { useState } from 'react';
import './Menu.sass';

// import components
import MenuList from '../MenuList';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-menu');

const Menu = () => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    };

    const closeMobileMenu = () => {
        if (window.innerWidth < 448) {
            setCollapsed(false);
        }
    };

    return (
        <div className={cn() + ' ' + cn({ '-collapsed': collapsed })} role="navigation">
            <div className={cn('toggle')}>
                <button
                    className={cn('trigger')}
                    onClick={() => toggleMenu()}
                    aria-label="Toggle menu"
                    aria-expanded={collapsed}
                >
                    <span className={cn('icon')}></span>
                </button>
            </div>
            <div className={cn('list')}>
                <MenuList collapsed={collapsed} closeMenu={closeMobileMenu} />
            </div>
        </div>
    );
};

export default Menu;
