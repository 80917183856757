import React from 'react';
import './Title.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-content-title');

const Title = ({ children }) => <div className={cn()}>{children}</div>;

export default Title;
