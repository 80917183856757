import { useState } from 'react';

const useTags = ({ setValue }) => {
    const [tags, setTags] = useState([]);

    const handleTagEvent = e => {
        if (e.key === 'Enter' || e.target.id === 'tag-trigger') {
            const input = document.querySelector('#tagInput').value.trim();
            e.preventDefault();
            addTag(input);
            setValue('tags', '');
        }
    };

    const addTag = tag => {
        if (!tags.some(item => tag === item.tag) && tag !== '' && tag.length <= 25) {
            setTags([...tags, { tag: tag }]);
        }
    };

    const removeTag = removeTag => {
        setTags(previousTags => previousTags.filter(({ tag }) => tag !== removeTag));
    };

    return {
        tags,
        setTags,
        handleTagEvent,
        addTag,
        removeTag,
    };
};

export default useTags;
