import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import getPostit from '../get';

const linkPostIt = (code, postitId, fieldId, closeModal = null) => {
    axios
        .put(`${process.env.REACT_APP_BACKEND_API}/post-it/${code}/${postitId}/${fieldId}`)
        .then(() => {
            notification([
                {
                    message: 'Successfully added post-it',
                    status: 'success',
                },
            ]);
            getPostit();
            if (closeModal) {
                closeModal();
            }
        })
        .catch(error => {
            console.log(error);
        });
};

export default linkPostIt;
