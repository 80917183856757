import { useState, useMemo, useEffect } from 'react';
import LZString from 'lz-string';

export const useDrawing = ({ drawing, canvasRef }) => {
    const DEFAULT_CANVAS_WIDTH = 819;
    const DEFAULT_BRUSH_SIZE = 1;

    const [canvasWidth, setCanvasWidth] = useState(DEFAULT_CANVAS_WIDTH);
    const [brushSize, setBrushSize] = useState(DEFAULT_BRUSH_SIZE);
    const [brushColor, setBrushColor] = useState('#000000');
    const [hideGrid, setHideGrid] = useState(true);
    const [brushSizeSliderVisible, toggleBrushSizeSliderVisibility] = useState(false);

    useEffect(() => {
        if (!canvasRef.current) return;

        canvasRef.current.drawGrid(canvasRef.current.ctx.grid);
    }, [hideGrid]);

    const gridColor = hideGrid ? '#FFFFFF' : '#CCCCCC';
    const canvasHeight = (canvasWidth / 4) * 3;
    const brushRadius = (canvasWidth / 400) * brushSize;
    const uncompressedDrawing = useMemo(() => (drawing ? LZString.decompressFromUTF16(drawing) : undefined), [drawing]);

    const undoDraw = event => {
        if (!canvasRef.current) return;

        event.preventDefault();
        canvasRef.current.undo();
    };

    const clearDrawing = event => {
        if (!canvasRef.current) return;

        event.preventDefault();
        canvasRef.current.clear();
    };

    const setWrapperRef = wrapperElement => setCanvasWidth(wrapperElement?.offsetWidth ?? 400);
    const onChangeBrushColor = event => setBrushColor(event.target.value);

    return {
        canvasWidth,
        brushSize,
        brushColor,
        hideGrid,
        brushSizeSliderVisible,
        gridColor,
        canvasHeight,
        brushRadius,
        uncompressedDrawing,
        setBrushSize,
        setHideGrid,
        toggleBrushSizeSliderVisibility,
        undoDraw,
        clearDrawing,
        setWrapperRef,
        onChangeBrushColor,
    };
};
