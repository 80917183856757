import React, { useEffect } from 'react';
import './Toast.sass';
import { setGlobal, useGlobal } from 'reactn';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-toast');

const Toast = () => {
    const [messages] = useGlobal('notifications');

    useEffect(() => {
        setTimeout(() => {
            setGlobal({
                notifications: null,
            });
        }, 5000);
    }, [messages]);

    return (
        <>
            {messages && (
                <div className={cn()} aria-live="polite">
                    {messages.map((item, key) => (
                        <div key={key} className={cn('message') + ' ' + cn('message-' + item.status)}>
                            {item.message}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Toast;
