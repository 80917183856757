import React, { useState } from 'react';

// import components
import Modal from '../../Modal';
import MeetingForm from '../../Forms/MeetingForm';

const Meeting = () => {
    const [modalShow, setModalShow] = useState(false);

    const closeModal = () => {
        setModalShow(false);
    };

    const showModal = () => {
        setModalShow(true);
    };

    return (
        <>
            <button className="o-button" onClick={() => showModal()}>
                Create meeting
            </button>
            {modalShow && (
                <Modal closeModal={closeModal} title="Meeting">
                    <MeetingForm closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default Meeting;
