import 'pusher-js';
import Echo from 'laravel-echo';

const laravelEcho = new Echo({
    broadcaster: 'null',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: 'eu',
    forceTLS: true,
    auth: {
        headers: {
            Authorization: localStorage.getItem('token'),
        },
    },
});

export const socketAuth = () => {
    laravelEcho.disconnect();
    laravelEcho.options.broadcaster = 'pusher';
    laravelEcho.connector.options.auth.headers['Authorization'] = localStorage.getItem('token');
    laravelEcho.connect();
    laravelEcho.connector.pusher.config.authEndpoint = process.env.REACT_APP_BACKEND_API + `/broadcasting/auth`;
};

export default laravelEcho;
