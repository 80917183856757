import React, { useState } from 'react';
import './PostItAdd.sass';
import ReactMarkdown from 'react-markdown';

// import components
import Modal from '../../Modal';
import bemClassNames from '../../../helpers/bemClassNames';
import { useEffect, useGlobal } from 'reactn';
import getPostits from '../../../services/postit/get';
import linkPostIt from '../../../services/postit/link';
import Tags from '../../Tag';
import Filter from '../../Filter';
import filterPostitsHelper from '../../../helpers/filters/filterPostits';
import AddPostIt from '../PostIt';

const cn = bemClassNames('c-post-it-add');

const PostItAdd = ({ code, fieldId }) => {
    const [postits] = useGlobal('postits');
    const [localPostits, setLocalPostits] = useState([]);
    const [filterArray, setFilterArray] = useState([]);

    const setFilter = filter => {
        if (filter.length > 0) {
            setFilterArray(filter);
        }
        setLocalPostits(postits);
    };

    useEffect(() => {
        if (!postits) {
            return;
        }
        if (filterArray.length === 0) {
            setLocalPostits(postits);
            return;
        }
        setLocalPostits(filterPostitsHelper(postits, filterArray));
    }, [postits, filterArray]);

    useEffect(() => {
        getPostits();
    }, []);

    const [modalShow, setModalShow] = useState(false);

    const handlePostIt = postitId => {
        linkPostIt(code, postitId, fieldId, closeModal);
    };

    const closeModal = () => {
        setModalShow(false);
        setFilterArray([]);
    };

    const showModal = () => {
        setModalShow(true);
    };

    return (
        <>
            <button className={cn('button')} onClick={() => showModal()}>
                <span className={cn('icon')}>add</span>
                post-it
            </button>
            {modalShow && (
                <Modal closeModal={closeModal} title="Add post-its" fixedDimensions={true}>
                    <div className={cn()}>
                        <div className={cn('options')}>
                            <AddPostIt linkData={{ field: fieldId, code: code }} />
                            <Filter data={postits} setFilter={setFilter} />
                        </div>
                        {localPostits.map(
                            postit =>
                                !postit.meeting_fields_id && (
                                    <div key={postit.id} className={cn('post-it')}>
                                        <div className={cn('post-it-title')}>{postit.title}</div>
                                        <div className={cn('content')}>
                                            <ReactMarkdown>{postit.content}</ReactMarkdown>
                                        </div>
                                        <div className={cn('post-it-tags')}>
                                            <Tags tags={postit.tags} />
                                        </div>
                                        <button
                                            className={cn('button', 'post-it-button')}
                                            onClick={() => handlePostIt(postit.id)}
                                        >
                                            Add post-it
                                            <span className={cn('icon')}>add</span>
                                        </button>
                                    </div>
                                ),
                        )}
                    </div>
                </Modal>
            )}
        </>
    );
};

export default PostItAdd;
