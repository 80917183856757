import { getGlobal, setGlobal } from 'reactn';
import linkPostit from './linkPostit.helper';

const meetingChangeHandler = event => {
    const global = getGlobal();

    let updatedMeeting = global.meeting;

    switch (event.type) {
        case 'field_new':
            updatedMeeting.fields.push(event.data);
            break;
        case 'field_edit':
            updatedMeeting.fields.filter(field => {
                if (field.id === parseInt(event.data.id)) {
                    field.field = event.data.field;
                }
            });
            break;
        case 'field_remove':
            updatedMeeting.fields = updatedMeeting.fields.filter(obj => obj.id !== parseInt(event.data.id));
            break;
        case 'meeting_edit':
            updatedMeeting.title = event.data.title;
            break;
        case 'postit_link':
            linkPostit(event, updatedMeeting);
            break;
        case 'postit_unlink':
            updatedMeeting.fields.map(field => {
                field.post_its = field.post_its.filter(postit => postit.id !== parseInt(event.data.postitId));
            });
            break;
        case 'postit_vote':
            updatedMeeting.fields.map(field => {
                field.post_its.map(postit => {
                    if (postit.id === parseInt(event.data.postitId)) {
                        postit.votes = event.data.votes;
                    }
                });
            });
            break;
        case 'meeting_archive':
            updatedMeeting.archived = event.data.archived;
            break;
        case 'importance':
            updatedMeeting.fields.map(field => {
                field.post_its.map(postit => {
                    if (postit.id === parseInt(event.data.postitId)) {
                        postit.less_important = event.data.lessImportant;
                    }
                });
            });
            break;
    }

    setGlobal({
        meeting: updatedMeeting,
    });
};

export default meetingChangeHandler;
