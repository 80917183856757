import React from 'react';
import './InputError.sass';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-input-message');

const InputError = ({ message }) => (
    <div className={cn()}>
        <span className={cn('icon')}></span>
        {message ? message : 'This field is invalid'}
    </div>
);

export default InputError;
