import { getGlobal } from 'reactn';

export default [
    config => {
        if (getGlobal('loggedIn')) {
            config.headers.authorization = localStorage.getItem('token');
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
];
