import React, { useState, useRef } from 'react';
import './MarkdownEditor.sass';
import Label from '../Label';

// import components
import MarkdownTextarea from './components/MarkdownTextarea';

// import hooks
import bemClassNames from '../../helpers/bemClassNames';
import useMarkdownOptions from './hooks/useMarkdownOptions';

const cn = bemClassNames('c-markdown-editor');

const MarkdownEditor = ({ name, label, errors, data, inputRef }) => {
    const [preview, setPreview] = useState(false);
    const [content, setContent] = useState(data ? data.content : '');
    const editorRef = useRef(null);

    const rows = (content && Math.ceil(content.length / 50)) ?? 3;

    const { insertTextStyle, insertTextBlock, insertCodeBlock } = useMarkdownOptions({ editorRef, setContent });

    return (
        <div className="o-input-group">
            <Label input="bodyInput" errors={errors.content}>
                {label}
            </Label>
            <div className={cn()}>
                <div className={cn('toolbar')}>
                    <div className={cn('toolbar-group')}>
                        <button
                            type="button"
                            className={cn('view-mode-button') + `${preview ? '' : ' active'}`}
                            onClick={() => setPreview(false)}
                        >
                            Write
                        </button>
                        <button
                            type="button"
                            className={cn('view-mode-button') + `${preview ? ' active' : ''}`}
                            onClick={() => setPreview(true)}
                        >
                            Preview
                        </button>
                    </div>
                    <div className={cn('toolbar-group' + ' style-buttons') + `${preview ? '' : ' active'}`}>
                        <div className={cn('style-button-group')}>
                            <button type="button" className={cn('style-button')} onClick={() => insertTextStyle('*')}>
                                format_italic
                            </button>
                            <button type="button" className={cn('style-button')} onClick={() => insertTextStyle('**')}>
                                format_bold
                            </button>
                        </div>
                        <div className={cn('style-button-group')}>
                            <button type="button" className={cn('style-button')} onClick={() => insertTextBlock('>')}>
                                format_quote
                            </button>
                        </div>
                        <div>
                            <button type="button" className={cn('style-button')} onClick={() => insertCodeBlock()}>
                                code
                            </button>
                        </div>
                    </div>
                </div>
                <MarkdownTextarea
                    preview={preview}
                    name={name}
                    content={content}
                    rows={rows}
                    errors={errors}
                    editorRef={editorRef}
                    inputRef={inputRef}
                    setContent={setContent}
                />
            </div>
        </div>
    );
};

export default MarkdownEditor;
