import React from 'react';

// import services
import resetService from '../../../services/reset/email';

// import components
import { useForm } from 'react-hook-form';
import Label from '../../Label';

// import helpers
import mailValidate from '../../../helpers/validation/email';

const ResetPasswordForm = ({ closeModal }) => {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => resetService(data, closeModal);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="o-input-group">
                <Label input="emailInput" errors={errors.email}>
                    Email
                </Label>
                <input
                    type="email"
                    placeholder="Email"
                    className="o-input"
                    id="emailInput"
                    name="email"
                    ref={register({
                        required: true,
                        validate: email => mailValidate(email) || 'This is not a valid email address',
                    })}
                    aria-required="true"
                    aria-invalid={errors.email ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <button type="submit" className="o-button">
                    Receive reset instructions
                </button>
            </div>
        </form>
    );
};

export default ResetPasswordForm;
