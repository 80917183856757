import axios from '../axios';

const verify = ({ setStatus, token }) => {
    axios
        .get(process.env.REACT_APP_BACKEND_API + '/auth/register/' + token)
        .then(response => {
            setStatus({ title: response.data.message, status: true });
        })
        .catch(error => {
            setStatus({ title: error.response.data.message, status: false });
        });
};

export default verify;
