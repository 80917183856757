import React, { useRef } from 'react';
import './Meeting.sass';

// import services
import socketMeeting from '../../services/meeting/socket';
import archiveMeetingService from '../../services/archive/archive';
import unarchiveMeetingService from '../../services/archive/unarchive';

// import components
import PostItAdd from '../../components/Modals/PostItAdd';
import MeetingTitleEdit from '../../components/Modals/MeetingTitleEdit';
import MeetingField from './MeetingField';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';
import { useEffect, useGlobal, useState } from 'reactn';
import getMeeting from '../../services/meeting/get';
import Loading from '../../components/Loading';
import Header from '../../components/Title';
import PageBody from '../../components/PageBody';
import MeetingPostIt from './MeetingPostIt';
import MeetingFieldAdd from '../../components/Modals/MeetingFieldAdd';
import useTakeScreenshot from './useTakeScreenshot';
import { useAutoScrollState } from './hooks/useAutoScrollState';

const cn = bemClassNames('c-meeting');

const Meeting = ({ match }) => {
    const [highlightPostIt, setHighlightPostIt] = useState();
    const code = match.params.code;
    const [meeting, setMeeting] = useGlobal('meeting');
    const [currentSocketCode] = useGlobal('subscribedSocketCode');
    const { autoScroll, setAutoScroll } = useAutoScrollState();
    const meetingFieldsRef = useRef(null);

    const { triggerScreenshotDownload, isScreenshot, screenshotElementRef } = useTakeScreenshot(meeting?.title);

    const setHighlightPostItFromHash = () => {
        const highlightPostIt = parseInt(window.location.hash.replace('#postit', ''));
        setHighlightPostIt(highlightPostIt);
    };

    useEffect(() => {
        getMeeting(code);
        socketMeeting({ code, currentSocketCode });
    }, [code]);

    useEffect(() => {
        window.addEventListener('hashchange', setHighlightPostItFromHash);
        setHighlightPostItFromHash();

        return () => window.removeEventListener('hashchange', setHighlightPostItFromHash);
    }, []);

    useEffect(() => {
        if (meeting && meeting.code !== code) {
            setMeeting(null);
        }
    }, [meeting, code]);

    const archiveMeeting = () => {
        archiveMeetingService(code);
    };

    const unArchiveMeeting = () => {
        unarchiveMeetingService(code);
    };

    const takeScreenshot = async () => {
        const content = screenshotElementRef.current;
        const meetingFields = meetingFieldsRef.current;

        let neededWidth = 0;

        for (var child of meetingFields.children) {
            const style = getComputedStyle(child);
            const marginLeft = parseInt(style.marginLeft);
            const marginRight = parseInt(style.marginRight);
            neededWidth += child.offsetWidth + marginLeft + marginRight;
        }

        content.style.width = `${neededWidth}px`;
        content.style.padding = '16px';

        await triggerScreenshotDownload();

        content.style.width = 'auto';
        content.style.padding = '';
    };

    return (
        <>
            {meeting ? (
                <div className={cn()} ref={screenshotElementRef}>
                    <Header>
                        <div className={cn('title')}>
                            <h1>{meeting.title}</h1>
                            {!meeting.archived && !isScreenshot && (
                                <MeetingTitleEdit id={meeting.id} title={meeting.title} />
                            )}
                        </div>
                        {!isScreenshot && (
                            <div className={cn('actions')}>
                                <button onClick={takeScreenshot} className={cn('button', 'download')}>
                                    <span className={cn('icon')}>photo_camera</span>Download
                                </button>
                                {!meeting.archived ? (
                                    <>
                                        <button
                                            className={cn('button', 'autoScroll', { 'autoScroll--active': autoScroll })}
                                            onClick={() => setAutoScroll(!autoScroll)}
                                        >
                                            <span className={cn('icon')}>
                                                {autoScroll ? 'check_box' : 'check_box_outline_blank'}
                                            </span>
                                            Auto scroll
                                        </button>
                                        <button className={cn('button', 'archive')} onClick={() => archiveMeeting()}>
                                            <span className={cn('icon')}>archive</span>Archive
                                        </button>
                                        <MeetingFieldAdd id={meeting.id} />
                                    </>
                                ) : (
                                    <div className={cn('archived')}>
                                        Editing is disabled because this meeting has been archived
                                        <button
                                            className={cn('button', 'archive', 'archived-button')}
                                            onClick={() => unArchiveMeeting()}
                                        >
                                            <span className={cn('icon')}>unarchive</span>Unarchive
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Header>
                    <PageBody>
                        <div className={cn('fields')} ref={meetingFieldsRef}>
                            {meeting.fields.length ? (
                                meeting.fields.map(field => (
                                    <div key={field.id} className={cn('field')}>
                                        <div className={cn('field-header')}>
                                            <MeetingField
                                                field={field}
                                                archived={meeting.archived}
                                                isScreenshot={isScreenshot}
                                            />
                                        </div>
                                        <ul className={cn('field-post-its')}>
                                            {field.post_its.length ? (
                                                <>
                                                    {field.post_its.map(postit => (
                                                        <MeetingPostIt
                                                            postit={postit}
                                                            key={postit.id}
                                                            active={postit.id === highlightPostIt}
                                                            meeting={meeting}
                                                            code={code}
                                                            isScreenshot={isScreenshot}
                                                        />
                                                    ))}
                                                </>
                                            ) : (
                                                <li className={cn('field-empty')}>No post-its in this field</li>
                                            )}
                                        </ul>
                                        {!meeting.archived && !isScreenshot && (
                                            <div className={cn('field-action')}>
                                                <PostItAdd code={code} fieldId={field.id} />
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>no fields</p>
                            )}
                        </div>
                    </PageBody>
                </div>
            ) : (
                <Loading />
            )}
        </>
    );
};

export default Meeting;
