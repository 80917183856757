import React, { useState } from 'react';

// import components
import Modal from '../../Modal';
import RegisterForm from '../../Forms/RegisterForm';

const Register = () => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <button className="o-button-link" href="#" onClick={() => setShowModal(true)}>
                Register one
            </button>
            {showModal && (
                <Modal closeModal={closeModal} title="Register">
                    <RegisterForm closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default Register;
