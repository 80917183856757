import React from 'react';
import bemClassNames from '../../../../helpers/bemClassNames';
import BrushSize from './components/BrushSize';

import BrushColor from './components/BrushColor';
import ToggleGrid from './components/ToggleGrid';
import UndoButton from './components/UndoButton';
import ClearButton from './components/ClearButton';
import ToolbarGroup from './components/ToolbarGroup';
import '../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const Toolbar = ({
    setHideGrid,
    setBrushSize,
    toggleBrushSizeSliderVisibility,
    undoDraw,
    clearDrawing,
    onChangeBrushColor,
    hideGrid,
    brushSizeSliderVisible,
    brushSize,
    brushColor,
}) => {
    return (
        <div className={cn('toolbar')}>
            <ToolbarGroup>
                <BrushSize
                    toggleBrushSizeSliderVisibility={toggleBrushSizeSliderVisibility}
                    brushSizeSliderVisible={brushSizeSliderVisible}
                    setBrushSize={setBrushSize}
                    brushSize={brushSize}
                />
                <BrushColor brushColor={brushColor} onChangeBrushColor={onChangeBrushColor} />
            </ToolbarGroup>

            <ToggleGrid setHideGrid={setHideGrid} hideGrid={hideGrid} />
            <UndoButton undoDraw={undoDraw} />
            <ClearButton clearDrawing={clearDrawing} />
        </div>
    );
};

export default Toolbar;
