import { getGlobal, setGlobal } from 'reactn';
import getPostit from '../../services/postit/get/getPostit';
import addOrReplacePostit from './addOrReplacePostit.helper';

const linkPostit = (event, updatedMeeting) => {
    const postitId = parseInt(event.data.postitId);
    const fieldId = parseInt(event.data.fieldId);

    const field = updatedMeeting.fields.find(({ id }) => id === fieldId);

    if (!field) {
        return;
    }

    addOrReplacePostit(field.post_its, event.data.postit);

    const autoScroll = !!getGlobal().autoScroll;
    if (autoScroll) {
        window.location.hash = `postit${event.data.postit.id}`;
    }

    const meetingCode = updatedMeeting.code;

    getPostit(postitId, meetingCode, data => {
        addOrReplacePostit(field.post_its, data);
        setGlobal({
            meeting: updatedMeeting,
        });
    });
};

export default linkPostit;
