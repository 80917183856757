const filterPostits = (postits, filterArray) => {
    return postits
        .filter(postit => postit.tags.some(r => filterArray.indexOf(r.tag) >= 0))
        .map(postit => {
            postit.tagCount = 0;
            postit.tags.forEach(tag => {
                if (postit.tags.some(() => filterArray.indexOf(tag.tag) >= 0)) {
                    postit.tagCount++;
                }
            });
            return postit;
        })
        .sort((a, b) => {
            return b.tagCount - a.tagCount;
        });
};

export default filterPostits;
