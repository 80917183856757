import React from 'react';
import { useGlobal } from 'reactn';
import './MenuList.sass';
import { NavLink } from 'react-router-dom';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-menu-list');

const MenuList = ({ collapsed, closeMenu }) => {
    const [user] = useGlobal('user');

    return (
        <ul className={cn() + ' ' + cn({ '-collapsed': collapsed })}>
            <li className={cn('item')}>
                <NavLink to="/post-its" className={cn('link')}>
                    <div className={cn('content')} onClick={closeMenu}>
                        <span className={cn('icon')}>note</span>
                        <p className={cn('text')}>Post-its</p>
                    </div>
                </NavLink>
            </li>
            <li className={cn('item')}>
                <NavLink to="/meetings" className={cn('link')}>
                    <div className={cn('content')} onClick={closeMenu}>
                        <span className={cn('icon')}>supervisor_account</span>
                        <p className={cn('text')}>Meetings</p>
                    </div>
                </NavLink>
            </li>
            <li className={cn('item')}>
                <NavLink to="/archive" className={cn('link')}>
                    <div className={cn('content')} onClick={closeMenu}>
                        <span className={cn('icon')}>archive</span>
                        <p className={cn('text')}>Archive</p>
                    </div>
                </NavLink>
            </li>
            <li className={cn('item') + ' ' + cn('item-account')}>
                <NavLink to="/account" className={cn('link')}>
                    <div className={cn('content')} onClick={closeMenu}>
                        {user && (
                            <div
                                className={cn('account')}
                                style={{
                                    background: user.color,
                                }}
                            >
                                {user.initials}
                            </div>
                        )}
                        <p className={cn('text')}>Account</p>
                    </div>
                </NavLink>
            </li>
        </ul>
    );
};

export default MenuList;
