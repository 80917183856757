import React, { useState } from 'react';

// import components
import Modal from '../../Modal';
import AccountDetailsForm from '../../Forms/AccountDetailsForm';

const AccountDetailsEdit = () => {
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <button className="o-button" href="#" onClick={() => setShowModal(true)}>
                Edit
            </button>
            {showModal && (
                <Modal closeModal={closeModal} title="Edit account details">
                    <AccountDetailsForm closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default AccountDetailsEdit;
