import React from 'react';
import './Tag.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-tag');

const Tag = ({ tags = [], removeTag }) => {
    return (
        <div className={cn()}>
            <ul className={cn('list')}>
                {tags.sort().map(({ tag }) => (
                    <li key={tag} className={cn('list-item')}>
                        <span className={cn('label')}>{tag}</span>
                        {removeTag && (
                            <button type="button" className={cn('close')} onClick={() => removeTag(tag)}>
                                <span className={cn('icon')}>close</span>
                            </button>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Tag;
