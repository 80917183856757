import React, { useState } from 'react';
import './MeetingFieldAdd.sass';

// import components
import Modal from '../../Modal';
import bemClassNames from '../../../helpers/bemClassNames';

import AddFieldForm from '../../Forms/AddFieldForm';

const cn = bemClassNames('c-meeting-field-add');

const MeetingFieldAdd = ({ id }) => {
    const [modalShow, setModalShow] = useState(false);

    const closeModal = () => {
        setModalShow(false);
    };

    return (
        <>
            <button className={cn('button')} onClick={() => setModalShow(true)}>
                <span className={cn('icon')}>add</span>Field
            </button>
            {modalShow && (
                <Modal closeModal={closeModal} title="Add field">
                    <AddFieldForm id={id} closeModal={closeModal} />
                </Modal>
            )}
        </>
    );
};

export default MeetingFieldAdd;
