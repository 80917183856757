import { setGlobal } from 'reactn';

// import service
import authCheck from '../../services/auth';

const authentication = () => {
    if (localStorage.getItem('token')) {
        setGlobal({
            loginLoading: true,
            loggedIn: true,
        });
        authCheck();
        if (new Date(localStorage.getItem('expires_at')) <= new Date()) {
            setGlobal({
                loginLoading: false,
                loggedIn: false,
            });
        }
    }
};

export default authentication;
