import React from 'react';
import './Field.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-field');

const Field = ({ field = [], removeField }) => {
    return (
        <div className={cn()}>
            <ul className={cn('list')}>
                {field.sort().map(({ field }) => (
                    <li tabIndex="0" key={field} className={cn('list-item')}>
                        {field}
                        <button
                            type="button"
                            className={cn('close') + ' o-button-link'}
                            onClick={() => removeField(field)}
                        >
                            <span className={cn('icon')} aria-label="Remove field">
                                remove_circle_outline
                            </span>
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Field;
