import React, { useEffect, useState } from 'react';
import bemClassNames from '../../helpers/bemClassNames';
import downVoteService from '../../services/postit/downVote';
import upVoteService from '../../services/postit/upVote';

const cn = bemClassNames('c-meeting-post-it-voting');

const MeetingPostItVoting = ({ votes, postitId, userId, code, archived }) => {
    const [ownVotes, setOwnVotes] = useState([]);
    const [animationRunning, setAnimationRunning] = useState(false);

    const downVote = () => {
        if (ownVotes.length) {
            downVoteService(postitId, code);
            setOwnVotes([]);
        }
    };

    const upVote = () => {
        setAnimationRunning(true);
        upVoteService(postitId, code);
    };

    useEffect(() => {
        setTimeout(() => setAnimationRunning(false), 1000);
    }, [animationRunning]);

    useEffect(() => {
        const ownVotes = votes.filter(vote => vote.user.id === userId);
        setOwnVotes(ownVotes);
    }, [votes, userId]);

    return (
        <div className={cn()}>
            <button className={cn('trigger')} onClick={() => upVote()} disabled={animationRunning || archived}>
                <span
                    className={cn(
                        'icon',
                        { 'icon--voted': ownVotes.length > 0 && !archived },
                        { 'icon--active': votes.length > 0 && !archived },
                        { 'icon--disabled': archived },
                    )}
                >
                    arrow_upward
                </span>
                {animationRunning && <div className={cn('trigger-animation')}></div>}
            </button>
            {votes.length > 0 && <span className={cn('votes')}>{votes.length}</span>}
            <button className={cn('trigger')} onClick={() => downVote()} disabled={archived}>
                <span className={cn('icon', { 'icon--disabled': ownVotes.length === 0 || archived })}>
                    arrow_downward
                </span>
            </button>
        </div>
    );
};

export default MeetingPostItVoting;
