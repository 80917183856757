import React, { useState } from 'react';
import './MeetingShare.sass';
import { setGlobal, useGlobal } from 'reactn';

// import components
import Modal from '../../Modal';
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-meeting-share');

const Meeting = () => {
    const [modalShow] = useGlobal('meetingModalStatus');
    const [modalData] = useGlobal('meetingModal');
    const [copyText, setCopyText] = useState('Copy code');

    const closeModal = () => {
        setGlobal({
            meetingModalStatus: false,
        });
    };

    const copyMeetingCode = () => {
        navigator.clipboard.writeText(modalData.code);
        setCopyText('Copied');
    };

    return (
        <>
            {modalShow && (
                <Modal closeModal={closeModal} title="Share meeting">
                    <div className={cn()}>
                        <div className={cn('header')}>
                            <div className={cn('effect')}>
                                <span className={cn('icon')}>share</span>
                            </div>
                        </div>
                        <div className={cn('share')}>
                            <span className={cn('code')}>{modalData.code}</span>
                            <button className="o-button" type="button" onClick={copyMeetingCode}>
                                {copyText}
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default Meeting;
