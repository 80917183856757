import React from 'react';
import Label from '../../../Label';

const InputPostItTitle = ({ name, label, errors, data, inputRef }) => (
    <div className="o-input-group">
        <Label input="titleInput" errors={errors[name]}>
            {label}
        </Label>
        <input
            type="text"
            placeholder="Title"
            name={name}
            id={name}
            className="o-input"
            aria-required="true"
            aria-invalid={errors[name] ? 'true' : 'false'}
            defaultValue={data && data.title}
            ref={inputRef}
        />
    </div>
);

export default InputPostItTitle;
