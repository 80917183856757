import React from 'react';
import './RegisterForm.sass';

// import services
import registerService from '../../../services/register';

// import components
import { useForm } from 'react-hook-form';
import Label from '../../Label';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';
import mailValidate from '../../../helpers/validation/email';

const cn = bemClassNames('c-register');

const RegisterForm = ({ closeModal }) => {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => registerService(data, closeModal);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="usernameInput" errors={errors.display_name}>
                    Username
                </Label>
                <input
                    type="text"
                    placeholder="Username"
                    className="o-input"
                    name="display_name"
                    id="usernameInput"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.display_name ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <Label input="emailInput" errors={errors.email}>
                    Email
                </Label>
                <input
                    type="email"
                    placeholder="Email"
                    className="o-input"
                    id="emailInput"
                    name="email"
                    ref={register({
                        required: true,
                        validate: email => mailValidate(email) || 'This is not a valid email address',
                    })}
                    aria-required="true"
                    aria-invalid={errors.email ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <Label input="passwordInput" errors={errors.password}>
                    Password
                </Label>
                <input
                    id="passwordInput"
                    type="password"
                    placeholder="Password"
                    autoComplete="new-password"
                    className="o-input"
                    name="password"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.password ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <Label input="passwordConfirmationInput" errors={errors.password_confirmation}>
                    Password confirmation
                </Label>
                <input
                    id="passwordConfirmationInput"
                    type="password"
                    placeholder="Confirm password"
                    autoComplete="new-password"
                    className="o-input"
                    name="password_confirmation"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.password_confirmation ? 'true' : 'false'}
                />
            </div>
            <div className={cn('button') + ' o-input-group'}>
                <button type="submit" className="o-button">
                    Register
                </button>
            </div>
        </form>
    );
};

export default RegisterForm;
