import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import getPostit from '../get';

const deletePostIt = id => {
    axios
        .delete(`${process.env.REACT_APP_BACKEND_API}/post-it/${id}`)
        .then(() => {
            notification([
                {
                    message: 'Successfully deleted post-it',
                    status: 'success',
                },
            ]);
            getPostit();
        })
        .catch(error => {
            console.log(error);
        });
};

export default deletePostIt;
