import React, { useEffect, useState, useGlobal } from 'reactn';
import { SliderPicker } from 'react-color';
import './AccountDetailsForm.sass';

// import services
import accountDetailsService from '../../../services/account/details';

// import components
import Label from '../../Label';
import { useForm } from 'react-hook-form';
import bemClassNames from '../../../helpers/bemClassNames';

// import helpers
const cn = bemClassNames('c-account-details');

const AccountDetailsForm = ({ closeModal }) => {
    const { register, handleSubmit, errors } = useForm();
    const [userinfo] = useGlobal('user');
    const [color, setColor] = useState();

    useEffect(() => {
        setColor(userinfo.color);
    }, [userinfo]);

    const onSubmit = data => {
        accountDetailsService(data, closeModal);
    };

    const changeColor = color => {
        setColor(color.hex);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="meetingTitleInput" errors={errors.meeting_id}>
                    Account color
                </Label>
                <input
                    type="text"
                    placeholder="Color"
                    className="o-input"
                    name="color"
                    ref={register({
                        required: true,
                    })}
                    readOnly={true}
                    hidden={true}
                    value={color || ''}
                />
                <div className={cn('color')}>
                    <div
                        className={cn('account')}
                        style={{
                            background: color,
                        }}
                    >
                        {userinfo.initials}
                    </div>
                    <div className={cn('slider')}>
                        <SliderPicker
                            color={color}
                            onChange={changeColor}
                            onChangeComplete={changeColor}
                            classname={cn('slider')}
                        />
                    </div>
                </div>
            </div>
            <div className="o-input-group">
                <Label input="meetingTitleInput" errors={errors.meeting_id}>
                    Account initials (2 characters)
                </Label>
                <input
                    type="text"
                    placeholder="Initials"
                    className="o-input"
                    name="initials"
                    ref={register({
                        required: true,
                    })}
                    defaultValue={userinfo.initials || ''}
                />
            </div>
            <div className="o-input-group">
                <button type="submit" className="o-button">
                    Set details
                </button>
            </div>
        </form>
    );
};

export default AccountDetailsForm;
