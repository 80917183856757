import notification from '../../helpers/errorHandling';
import { setGlobal } from 'reactn';

export default [
    response => response,
    error => {
        const notifications = [];
        switch (error.response.status) {
            case 422:
                for (const prop in error.response.data.errors) {
                    notifications.push({ message: error.response.data.errors[prop][0], status: 'error' });
                }
                break;
            case 401:
                setGlobal({
                    loggedIn: false,
                });
                break;
            case 409:
                notifications.push({ message: error.response.data.message, status: 'warning' });
                break;
            case 403:
                notifications.push({ message: error.response.data.message, status: 'error' });
                break;
            default:
                notifications.push({ message: 'Something went wrong, please try again', status: 'error' });
                break;
        }
        notification(notifications);

        //do something with error
        return Promise.reject(error);
    },
];
