import axios from '../../axios';
import notification from '../../../helpers/errorHandling';

const joinMeeting = (data, redirect) => {
    axios
        .post(process.env.REACT_APP_BACKEND_API + '/meeting/join', data)
        .then(() => {
            notification([
                {
                    message: 'Successfully joined meeting',
                    status: 'success',
                },
            ]);
            redirect(data.meeting_id);
        })
        .catch(error => {
            console.log(error);
        });
};

export default joinMeeting;
