import laravelEcho from '../../../helpers/laravelEcho';
import { setGlobal, getGlobal } from 'reactn';
import meetingChangeHandler from '../../../helpers/meetingChangeHandler';
import sortField from '../../../helpers/sorting/sortField';

const socketMeeting = ({ code, currentSocketCode }) => {
    if (currentSocketCode) {
        laravelEcho.leave(`meeting.m-${currentSocketCode}`);
    }
    laravelEcho.private(`meeting.m-${code}`).listen('.meetingEvent', e => {
        if (!['update', 'sort'].includes(e.action)) {
            return;
        }
        if (e.action === 'update') {
            meetingChangeHandler(e);
            return;
        }
        if (e.action === 'sort') {
            const global = getGlobal('meeting');
            global.meeting.fields.find(field => {
                if (field.id == parseInt(e.data.id)) {
                    field.sort = e.data.type;
                    sortField(field);
                }
            });
            setGlobal({
                meeting: global.meeting,
            });
        }
    });
    setGlobal({
        subscribedSocketCode: code,
    });
};

export default socketMeeting;
