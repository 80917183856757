import axios from '../axios';
import { setGlobal } from 'reactn';
import { socketAuth } from '../../helpers/laravelEcho/laravelEcho';
import colorGenerator from '../../helpers/colorGenerator';

const auth = () => {
    axios
        .get(process.env.REACT_APP_BACKEND_API + '/auth/user')
        .then(response => {
            socketAuth();
            if (!response.data.data.color) {
                response.data.data.color = '#' + colorGenerator(response.data.data.name);
            }
            if (!response.data.data.initials) {
                response.data.data.initials = response.data.data.name.substring(0, 2).toUpperCase();
            }
            setGlobal({
                loginLoading: false,
                user: response.data.data,
                loggedIn: true,
            });
        })
        .catch(() => {
            setGlobal({
                loginLoading: false,
                loggedIn: false,
            });
        });
};

export default auth;
