import React, { useEffect, useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import './Meetings.sass';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

// import comonents
import Header from '../../components/Title';
import PageBody from '../../components/PageBody';
import AddMeetings from '../../components/Modals/Meeting';
import ShareMeeting from '../../components/Modals/MeetingShare';
import JoinMeeting from '../../components/Modals/MeetingJoin';
import Loading from '../../components/Loading';
import getMeeting from '../../services/meeting/get';
import DeleteConfirm from '../../components/Modals/DeleteConfirm';
import { useState } from 'react';

// import services
import deleteMeetingService from '../../services/meeting/delete';

const cn = bemClassNames('c-meetings');

const Meetings = () => {
    const [meetings] = useGlobal('meetings');
    const [user] = useGlobal('user');
    const [awaitDeleteConfirmation, setAwaitDeleteConfirmation] = useState(false);
    const [deleteMeeting, setDeleteMeeting] = useState(null);

    useEffect(() => {
        getMeeting();
    }, []);

    const createdByCheck = id => {
        if (user && user.id === id) {
            return true;
        }
        return false;
    };

    const deleteConfirm = meeting => {
        setAwaitDeleteConfirmation(true);
        setDeleteMeeting(meeting);
    };

    const deleteMeetingAction = () => {
        deleteMeetingService(deleteMeeting.id);
        setAwaitDeleteConfirmation(false);
    };

    const cancelDeleteAction = () => {
        setAwaitDeleteConfirmation(false);
    };

    return (
        <div className={cn()}>
            <Header>
                <h1>Meetings</h1>
            </Header>
            <PageBody>
                <div className={cn('actions')}>
                    <AddMeetings />
                    <ShareMeeting />
                    {awaitDeleteConfirmation && (
                        <DeleteConfirm action={deleteMeetingAction} cancel={cancelDeleteAction} title="Delete meeting">
                            <div className={cn('delete-text')}>
                                <p>Are you sure you want to leave this meeting?</p>
                                <small>
                                    You can always join again by using the meeting code{' '}
                                    <strong>{deleteMeeting.code}</strong>
                                </small>
                            </div>
                        </DeleteConfirm>
                    )}
                    <JoinMeeting />
                </div>
                {meetings ? (
                    <div className={cn('grid')}>
                        {meetings.map(meeting => (
                            <div className={cn('card')} key={meeting.id}>
                                <Link to={'meetings/' + meeting.code} className={cn('link')}>
                                    <p className={cn('card-title')}>{meeting.title}</p>
                                    <div className={cn('card-info')}>
                                        <div className={cn('card-item')}>
                                            <div className={cn('card-icon')}>
                                                <span className={cn('icon')} aria-label="Amount of fields">
                                                    view_week
                                                </span>
                                            </div>
                                            <div className={cn('card-text')}>{meeting.fields.length} Fields</div>
                                        </div>
                                        <div className={cn('card-item')}>
                                            <div className={cn('card-icon')}>
                                                <span className={cn('icon')} aria-label="Creator of meeting">
                                                    library_add
                                                </span>
                                            </div>
                                            <div className={cn('card-text')}>
                                                Created by{' '}
                                                {createdByCheck(meeting.user_id) ? 'You' : meeting.display_name}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <div className={cn('actions')}>
                                    <button
                                        className={cn('actions-button')}
                                        onClick={() => deleteConfirm(meeting)}
                                        aria-label="Leave meeting"
                                    >
                                        <span className={cn('actions-icon') + ' ' + cn('icon')}>delete_outline</span>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={cn('loading')}>
                        <Loading />
                    </div>
                )}
            </PageBody>
        </div>
    );
};

export default Meetings;
