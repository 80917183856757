import React from 'react';
import './Content.sass';
import { Switch, Route, Redirect } from 'react-router-dom';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import components
import PostIts from '../../../views/PostIts';
import Meetings from '../../../views/Meetings';
import Account from '../../../views/Account';
import Meeting from '../../../views/Meeting';
import Archive from '../../../views/Archive';

const cn = bemClassNames('c-content');

const Content = () => (
    <div className={cn()} role="main">
        <Switch>
            <Route path="/post-its" component={PostIts} />
            <Route path="/meetings/:code" component={Meeting} />
            <Route path="/meetings" component={Meetings} />
            <Route path="/archive" component={Archive} />
            <Route path="/account" component={Account} />
            <Route path="/">
                <Redirect to="/post-its" />
            </Route>
        </Switch>
    </div>
);

export default Content;
