import React from 'react';
import bemClassNames from '../../../../../helpers/bemClassNames';

import '../../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const ToggleGrid = ({ setHideGrid, hideGrid }) => (
    <div className={cn('toolbar-group')}>
        <button
            type="button"
            aria-label="Toggle grid"
            title="Toggle grid"
            onClick={() => setHideGrid(!hideGrid)}
            className={cn('button')}
        >
            <span className={cn('icon')}>{hideGrid ? 'grid_off' : 'grid_on'}</span>
        </button>
    </div>
);

export default ToggleGrid;
