import React, { useGlobal, useEffect, useState } from 'reactn';
import './Account.sass';
import ntc from 'ntc';

// import helpers
import bemClassNames from '../../helpers/bemClassNames';

// import components
import Header from '../../components/Title';
import PageBody from '../../components/PageBody';
import Logout from '../../components/User/Logout';
import AccountDetailsEdit from '../../components/Modals/AccountDetailsEdit';
import EditPassword from '../../components/Modals/EditPassword';

const cn = bemClassNames('c-account');

const Account = () => {
    const [userinfo] = useGlobal('user');
    const [colorName, setColorName] = useState(null);

    useEffect(() => {
        if (userinfo) {
            setColorName(ntc.name(userinfo.color)[1]);
        }
    }, [userinfo]);

    return (
        <div className={cn()}>
            {userinfo && (
                <div>
                    <Header>
                        <div>
                            <h1 className={cn('title')}>Hello,</h1>
                            <h2 className={cn('sub-title')}>{userinfo.email}</h2>
                        </div>
                    </Header>
                    <PageBody>
                        <div className={cn('settings')}>
                            <div className={cn('block')}>
                                <div className={cn('block-account')}>
                                    <div
                                        className={cn('block-circle')}
                                        style={{
                                            background: userinfo.color,
                                        }}
                                    >
                                        {userinfo.initials}
                                    </div>
                                    <div className={cn('block-description', 'block-description-circle')}>
                                        <p className={cn('block-title')}>Account color & initials</p>
                                        <p className={cn('block-sub-title')}>
                                            <span
                                                style={{
                                                    color: userinfo.color,
                                                }}
                                            >
                                                {colorName}
                                            </span>
                                            , {userinfo.initials}
                                        </p>
                                    </div>
                                </div>
                                <div className={cn('block-action')}>
                                    <AccountDetailsEdit />
                                </div>
                            </div>
                        </div>
                        <div className={cn('settings')}>
                            <div className={cn('block')}>
                                <div className={cn('block-description')}>
                                    <p className={cn('block-title')}>Logout of your account</p>
                                    <p className={cn('block-sub-title')}>Logged in as {userinfo.email}</p>
                                </div>
                                <div className={cn('block-action')}>
                                    <Logout />
                                </div>
                            </div>
                            <div className={cn('block')}>
                                <div className={cn('block-description')}>
                                    <p className={cn('block-title')}>Password</p>
                                    <p className={cn('block-sub-title')}>*******</p>
                                </div>
                                <div className={cn('block-action')}>
                                    <EditPassword />
                                </div>
                            </div>
                            <div className={cn('block')}>
                                <div className={cn('block-description')}>
                                    <p className={cn('block-title')}>Username</p>
                                    <p className={cn('block-sub-title')}>{userinfo.name}</p>
                                </div>
                            </div>
                            <div className={cn('block')}>
                                <div className={cn('block-description')}>
                                    <p className={cn('block-title')}>Email address</p>
                                    <p className={cn('block-sub-title')}>{userinfo.email}</p>
                                </div>
                            </div>
                        </div>
                    </PageBody>
                </div>
            )}
        </div>
    );
};

export default Account;
