import React from 'react';
import './App.sass';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useGlobal } from 'reactn';

// import helpers
import authentication from './helpers/authentication';

//Import components
import Menu from './components/Menu';
import Wrapper from './components/layout/Wrapper';
import Content from './components/layout/Content';
import Auth from './views/Auth';
import Verify from './views/Verify';
import Reset from './views/Reset';
import Loading from './components/Loading';

authentication();

const App = () => {
    const [loggedIn] = useGlobal('loggedIn');
    const [loggedInLoading] = useGlobal('loginLoading');

    if (loggedInLoading === true) {
        return <Loading />;
    }

    return (
        <Wrapper>
            {loggedIn ? (
                <>
                    <Menu />
                    <Content />
                </>
            ) : (
                <Router>
                    <Switch>
                        <Route path="/verify/:token" component={Verify} />
                        <Route path="/reset/:token/:email" component={Reset} />
                        <Route path="/" component={Auth} />
                    </Switch>
                </Router>
            )}
        </Wrapper>
    );
};

export default App;
