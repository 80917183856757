import axios from '../../axios';
import { setGlobal } from 'reactn';

const getArchived = () => {
    axios
        .get(`${process.env.REACT_APP_BACKEND_API}/archive`)
        .then(response => {
            setGlobal({
                archived: response.data,
            });
        })
        .catch(error => {
            console.log(error);
        });
};

export default getArchived;
