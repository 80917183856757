import React, { useRef, useState } from 'react';
import './PostItForm.sass';

// import components
import { useForm } from 'react-hook-form';
import Label from '../../Label';
import Tags from '../../Tag';
import ReactMarkdown from 'react-markdown';

import InputPostItTitle from './components/InputPostItTitle';
import InputPostItTypeSelect from './components/InputPostItTypeSelect';
import InputPostItTags from './components/InputPostItTags';
import MarkdownEditor from '../../MarkdownEditor/MarkdownEditor';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import services
import DeleteConfirm from '../../Modals/DeleteConfirm';
import Drawing from '../../Drawing';
import PostItOptions from './components/PostItOptions';
import PostItActions from './components/PostItActions';

// import hooks
import useDelete from './hooks/useDelete';
import useTags from './hooks/useTags';
import useEdit from './hooks/useEdit';
import { usePostItForm } from './hooks/usePostItForm';

const cn = bemClassNames('c-post-it');

const PostItForm = ({ closeModal, data, view, linkData }) => {
    const [timeInfo, setTimeInfo] = useState(null);
    const { register, handleSubmit, errors, setValue, setError } = useForm();
    const [type, setType] = useState(data?.type ?? 'text');

    const canvasRef = useRef(null);
    const onTypeSelect = e => setType(e.target.value);

    const { awaitDeleteConfirmation, deleteConfirm, deletePostItAction, cancelDeleteAction } = useDelete({
        data,
        closeModal,
    });
    const { tags, addTag, setTags, handleTagEvent, removeTag } = useTags({ setValue });
    const { modalView, isEditModus, editMode, cancelEdit } = useEdit({ data, view, setTags, setType });
    const { onSubmit, suggestions } = usePostItForm({
        tags,
        canvasRef,
        type,
        isEditModus,
        data,
        closeModal,
        linkData,
        modalView,
        setTimeInfo,
        setError,
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            {awaitDeleteConfirmation && (
                <DeleteConfirm action={deletePostItAction} cancel={cancelDeleteAction} title="Delete post-it">
                    <div className={cn('delete-text')}>
                        <p>Are you sure you want to delete this post-it?</p>
                    </div>
                </DeleteConfirm>
            )}

            {modalView && data ? (
                <h3 className={cn('title')}>{data.title}</h3>
            ) : (
                <InputPostItTitle
                    name={'title'}
                    label={'Title'}
                    errors={errors}
                    data={data}
                    inputRef={register({
                        required: 'The title is required',
                        maxLength: { value: 250, message: 'Only 250 characters allowed' },
                    })}
                />
            )}
            {!modalView && (
                <InputPostItTypeSelect
                    name={'type'}
                    label={'Type'}
                    errors={errors}
                    data={data}
                    inputRef={register({})}
                    onChange={onTypeSelect}
                />
            )}

            {!modalView && type && type === 'text' && (
                <MarkdownEditor
                    name={'content'}
                    label={'Body'}
                    errors={errors}
                    data={data}
                    inputRef={register({
                        maxLength: { value: 5000, message: 'Only 5000 characters allowed' },
                    })}
                />
            )}

            {modalView && data && type && type === 'text' && (
                <div className={cn('content')}>
                    <ReactMarkdown>{data.content}</ReactMarkdown>
                </div>
            )}

            {type === 'drawing' && (
                <div className="o-input-group">
                    {!modalView && (
                        <Label input="bodyInput" errors={errors.content}>
                            Drawing
                        </Label>
                    )}
                    <Drawing
                        ref={canvasRef}
                        disabled={modalView}
                        className={cn('canvas', { 'canvas--edit': !modalView })}
                        drawing={data?.drawing}
                    />
                </div>
            )}

            {!modalView ? (
                <InputPostItTags
                    name={'tagInput'}
                    tags={modalView ? data.tags : tags}
                    removeTag={modalView ? null : removeTag}
                    inputRef={register({
                        required: false,
                    })}
                    errors={errors}
                    suggestions={suggestions}
                    onKeyPress={handleTagEvent}
                    onClick={handleTagEvent}
                    callback={addTag}
                />
            ) : (
                <Tags tags={tags} removeTag={removeTag} />
            )}

            {modalView ? (
                <PostItOptions timeInfo={timeInfo} onDelete={deleteConfirm} onCreate={editMode} />
            ) : (
                <PostItActions editMode={isEditModus} onClick={() => cancelEdit()} />
            )}
        </form>
    );
};

export default PostItForm;
