import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import { socketAuth } from '../../../helpers/laravelEcho/laravelEcho';
import { setGlobal } from 'reactn';
import setUserinfo from '../../userinfo';

const reset = (token, data) => {
    axios.post(`${process.env.REACT_APP_BACKEND_API}/auth/reset/${token}`, data).then(response => {
        notification([
            {
                message: 'Changed password',
                status: 'success',
            },
        ]);
        localStorage.setItem('token', 'Bearer ' + response.data.data.access_token);
        localStorage.setItem('expires_at', response.data.data.expires_at);
        socketAuth();
        setGlobal({
            loggedIn: true,
        });
        setUserinfo();
    });
};

export default reset;
