import axios from '../../axios';
import { setGlobal } from 'reactn';
import sortField from '../../../helpers/sorting/sortField';

const getMeeting = (code = '') => {
    axios
        .get(`${process.env.REACT_APP_BACKEND_API}/meeting/${code}`)
        .then(response => {
            if (code) {
                response.data.fields.forEach(field => {
                    sortField(field);
                });
                setGlobal({
                    meeting: response.data,
                });
            } else {
                setGlobal({
                    meetings: response.data,
                });
            }
        })
        .catch(error => {
            console.log(error);
        });
};

export default getMeeting;
