import React from 'react';
import './ResetPasswordFinal.sass';

// import services
import resetService from '../../../services/reset/password';

// import components
import { useForm } from 'react-hook-form';
import Label from '../../Label';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-reset-password-final');

const ResetPasswordFinalForm = ({ token }) => {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => resetService(token, data);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="passwordInput" errors={errors.password}>
                    Password
                </Label>
                <input
                    id="passwordInput"
                    type="password"
                    placeholder="Password"
                    className="o-input"
                    name="password"
                    autoComplete="new-password"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.password ? 'true' : 'false'}
                />
            </div>
            <div className="o-input-group">
                <Label input="passwordConfirmationInput" errors={errors.password_confirmation}>
                    Password confirmation
                </Label>
                <input
                    id="passwordConfirmationInput"
                    type="password"
                    placeholder="Confirm password"
                    className="o-input"
                    name="password_confirmation"
                    autoComplete="new-password"
                    ref={register({
                        required: true,
                    })}
                    aria-required="true"
                    aria-invalid={errors.password_confirmation ? 'true' : 'false'}
                />
            </div>
            <div className={cn('button') + ' o-input-group'}>
                <button type="submit" className="o-button">
                    Save new password
                </button>
            </div>
        </form>
    );
};

export default ResetPasswordFinalForm;
