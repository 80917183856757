import React from 'react';
import Label from '../../../Label';

const InputPostItTypeSelect = ({ name, label, errors, data, inputRef, onChange }) => (
    <div className="o-input-group">
        <Label input={name} errors={errors.content}>
            {label}
        </Label>

        <select
            className="o-select"
            name={name}
            defaultValue={data ? data.type : 'text'}
            id={name}
            ref={inputRef}
            onChange={onChange}
        >
            <option value="text">Text</option>
            <option value="drawing">Drawing</option>
        </select>
    </div>
);

export default InputPostItTypeSelect;
