import React, { useState } from 'react';
import './Tooltip.sass';
import bemClassNames from '../../helpers/bemClassNames';

const cn = bemClassNames('c-tooltip');

const Tooltip = ({ children, fullText }) => {
    const [hover, setHover] = useState(false);

    const showToolTip = () => {
        setHover(true);
    };

    const hideToolTip = () => {
        setHover(false);
    };

    return (
        <div className={cn()} onMouseEnter={showToolTip} onMouseLeave={hideToolTip}>
            {children}
            {hover && (
                <div className={cn('active')} aria-live="polite">
                    {fullText}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
