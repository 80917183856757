import React from 'reactn';
import './DeleteConfirm.sass';

// import components
import Modal from '../../Modal';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

const cn = bemClassNames('c-delete-confirm');

const DeleteConfirm = ({ action, cancel, children, title }) => {
    return (
        <Modal closeModal={cancel} title={title}>
            <div className={cn()}>
                <div className={cn('text')}>{children}</div>
                <div className={cn('actions')}>
                    <button className={cn('button') + ' o-button o-button-secondary'} onClick={() => cancel()}>
                        Cancel
                    </button>
                    <button className={cn('button') + ' o-button o-button-danger'} onClick={() => action()}>
                        Delete
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteConfirm;
