import classNames from 'classnames';

const bemClassNames = baseName => {
    return (...classes) => {
        if (classes.length === 0) {
            return baseName;
        }
        return `${baseName}-` + classNames(classes).split(' ').join(` ${baseName}-`);
    };
};

export default bemClassNames;
