import axios from '../../axios';
import notification from '../../../helpers/errorHandling';
import getPostit from '../get';

const updatePostit = (data, closeModal) => {
    axios
        .put(process.env.REACT_APP_BACKEND_API + '/post-it/' + data.id, data)
        .then(() => {
            notification([
                {
                    message: 'Successfully updated post-it',
                    status: 'success',
                },
            ]);
            getPostit();
            closeModal();
        })
        .catch(error => {
            console.log(error);
        });
};

export default updatePostit;
