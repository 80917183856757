import React, { useState } from 'react';
import bemClassNames from '../../helpers/bemClassNames';
import Dropdown from 'react-bootstrap/Dropdown';
import { useForm } from 'react-hook-form';

import deleteFieldService from '../../services/field/delete';
import updateFieldService from '../../services/field/update';
import DeleteConfirm from '../../components/Modals/DeleteConfirm';
import sortField from '../../services/field/sort';

const cn = bemClassNames('c-meeting');

const MeetingField = ({ field, archived, isScreenshot }) => {
    const { register, handleSubmit, setValue } = useForm();
    const [editState, setEditState] = useState(false);
    const [awaitDeleteConfirmation, setAwaitDeleteConfirmation] = useState(false);
    const [deleteField, setDeleteField] = useState(null);

    const onSubmit = data => {
        updateFieldService(field.id, data);
        setEditState(false);
    };

    const cancelEdit = () => {
        setEditState(false);
        setValue('field', field.field);
    };

    const deleteConfirm = () => {
        setAwaitDeleteConfirmation(true);
        setDeleteField(field);
    };

    const deleteMeetingAction = () => {
        deleteFieldService(deleteField.id);
        setAwaitDeleteConfirmation(false);
    };

    const cancelDeleteAction = () => {
        setAwaitDeleteConfirmation(false);
    };

    const setSorting = type => {
        sortField(field.id, type);
    };

    return (
        <>
            {awaitDeleteConfirmation && (
                <DeleteConfirm action={deleteMeetingAction} cancel={cancelDeleteAction} title="Delete field">
                    <div className={cn('delete-text')}>
                        <p>Are you sure you want to delete with fields and unlink all post-its?</p>
                    </div>
                </DeleteConfirm>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className={cn('field-form')} key={field.field}>
                <input
                    type="text"
                    placeholder="Field title"
                    className={editState ? 'o-input' : cn('field-title')}
                    name="field"
                    ref={register({
                        required: true,
                    })}
                    readOnly={!editState}
                    aria-required="true"
                    aria-label="Field title"
                    defaultValue={field.field && field.field}
                />
                {editState && (
                    <div className={cn('field-form-actions')}>
                        <button
                            type="button"
                            className={cn('field-form-button') + ' o-button o-button-secondary'}
                            onClick={() => cancelEdit()}
                        >
                            Cancel
                        </button>
                        <button className={cn('field-form-button') + ' o-button'} type="submit">
                            Save
                        </button>
                    </div>
                )}
            </form>
            {!editState && !isScreenshot && (
                <Dropdown alignRight className={cn('field-header-actions')}>
                    <Dropdown.Toggle className={cn('field-header-button')}>
                        <span className={cn('icon', 'field-header-icon')}>more_vert</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown drop={'left'}>
                            <Dropdown.Item href="#" onClick={() => setSorting('creator')}>
                                <span className={cn('icon', 'post-it-menu-icon')}>sort_by_alpha</span>
                                Sort by user
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setSorting('votes')}>
                                <span className={cn('icon', 'post-it-menu-icon')}>swap_vert</span>
                                Sort by votes
                            </Dropdown.Item>
                        </Dropdown>
                        {!archived && (
                            <>
                                <Dropdown.Item href="#" onClick={() => setEditState(true)}>
                                    <span className={cn('icon', 'post-it-menu-icon')}>edit</span>
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item href="#" onClick={() => deleteConfirm()}>
                                    <span className={cn('icon', 'post-it-menu-icon')}>delete</span>
                                    Delete
                                </Dropdown.Item>
                            </>
                        )}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
};

export default MeetingField;
