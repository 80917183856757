import React from 'react';
import bemClassNames from '../../../../../helpers/bemClassNames';

import '../../../Drawing.sass';

const cn = bemClassNames('c-drawing');

const BrushSize = ({ toggleBrushSizeSliderVisibility, brushSizeSliderVisible, setBrushSize, brushSize }) => (
    <>
        <button
            type="button"
            aria-label="Toggle brush options"
            title="Toggle brush options"
            className={cn('button')}
            onClick={() => toggleBrushSizeSliderVisibility(!brushSizeSliderVisible)}
        >
            <span className={cn('icon')}>brush</span>
            <span className={cn('icon')}>expand_more</span>
        </button>
        <div className={brushSizeSliderVisible ? cn('brush-size-select-wrapper') : 'hidden'}>
            <input type="range" min="1" max="15" onChange={e => setBrushSize(e.target.value)} value={brushSize} />
            {brushSize}
        </div>
    </>
);

export default BrushSize;
