import { setGlobal, getGlobal } from 'reactn';

const suggestTags = ammount => {
    const data = getGlobal('postits').postits;
    const tags = data.flatMap(e => e.tags);
    tags.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });
    const tagArray = tags.map(e => e.tag);
    let uniqueTags = [...new Set(tagArray)];
    const finalSuggestions = uniqueTags.splice(0, ammount);
    setGlobal({
        tagSuggestions: finalSuggestions,
    });
};

export default suggestTags;
