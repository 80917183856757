import React from 'react';
import { useForm } from 'react-hook-form';
import './Login.sass';

// import helpers
import bemClassNames from '../../../helpers/bemClassNames';

// import services
import login from '../../../services/login';
import Label from '../../Label';
import mailValidate from '../../../helpers/validation/email';

const cn = bemClassNames('c-login');

const Login = () => {
    const { register, handleSubmit, errors } = useForm();
    const onSubmit = data => login({ data });

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={cn()}>
            <div className="o-input-group">
                <Label input="loginEmailInput" errors={errors.email}>
                    Email
                </Label>
                <input
                    type="email"
                    placeholder="Email"
                    className="o-input"
                    name="email"
                    id="loginEmailInput"
                    ref={register({
                        required: true,
                        validate: email => mailValidate(email) || 'This is not a valid email address',
                    })}
                />
            </div>
            <div className="o-input-group">
                <Label input="loginPasswordInput" errors={errors.password}>
                    Password
                </Label>
                <input
                    type="password"
                    placeholder="Password"
                    className="o-input"
                    name="password"
                    id="loginPasswordInput"
                    autoComplete="current-password"
                    ref={register({
                        required: true,
                    })}
                />
            </div>
            <div className={cn('action') + ' o-input-group'}>
                <button type="submit" className="o-button">
                    Login
                </button>
            </div>
        </form>
    );
};

export default Login;
